import { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import config from 'config'
import { useLibrary } from 'state/useLibrary'

const animateRemoveLoader = () => {
  const loadingScreen = document.getElementById('loading-screen')
  const svgLoader = document.getElementById('svg-loader')

  loadingScreen && loadingScreen.classList.add('complete')
  svgLoader && svgLoader.setAttribute('fill', '#fff')
}

interface LibrarySplashScreenProps {
  children: JSX.Element
}

export function LibrarySplashScreen({ children }: LibrarySplashScreenProps) {
  const library = useLibrary((state) => state.library)
  const librarySplashSettings = useLibrary(
    (state) => state.librarySplashSettings,
  )
  const [isShowingSplashScreen, showSplashScreen] = useState(true)

  const timerId = useRef<ReturnType<typeof setTimeout> | null>()

  useEffect(() => {
    // NOTE: this effect only runs on mount, not on update
    animateRemoveLoader()

    if (librarySplashSettings?.enabled) {
      timerId.current = setTimeout(() => {
        showSplashScreen(false)
        timerId.current = null
      }, librarySplashSettings.seconds * 1000)
    } else {
      showSplashScreen(false)
    }

    return () => {
      if (timerId.current) {
        showSplashScreen(false)
        clearTimeout(timerId.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!isShowingSplashScreen) return children

  return (
    <div
      className="flex h-full w-full p-4"
      style={{
        backgroundColor: librarySplashSettings?.backgroundColor || '#ffffff',
        color: librarySplashSettings?.textColor || '#4d4d4d',
      }}>
      <div className="m-auto flex flex-col items-center justify-center space-y-2 md:space-y-4">
        <h1 className="text-2xl font-light md:text-3xl lg:text-4xl">
          <FormattedMessage
            id="library.splash.header"
            defaultMessage="hoopla is brought to you by"
          />
          <span className="sr-only">{library?.name}</span>
        </h1>

        {librarySplashSettings?.imageName && (
          <div
            style={{
              maxWidth: 450,
              maxHeight: 300,
            }}>
            <img
              className="block h-auto max-h-full w-full max-w-full"
              src={`${config.librarySplashUrl}${librarySplashSettings?.imageName}`}
              alt="library splash"
              aria-hidden="true"
            />
          </div>
        )}

        {librarySplashSettings?.displayText && (
          <p>{librarySplashSettings?.displayText}</p>
        )}
      </div>
    </div>
  )
}
