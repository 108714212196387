import { defaultIconProps } from 'components/common/icons'

export const KeyboardArrowLeft = ({
  size = defaultIconProps.size,
  className = defaultIconProps.className,
}: DefaultIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={size}
    height={size}
    fill="currentColor"
    className={className}>
    <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path>
  </svg>
)
