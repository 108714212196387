const KidsModeOn = () => (
  <svg
    width="76px"
    height="25px"
    viewBox="0 0 495 182"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <path
          d="M74.8,3.06471264 L47.06,45.3954023 C41.58,53.4842912 37.9366667,59.3766284 36.13,63.0724138 L36.13,3.06471264 L0.27,3.06471264 L0.27,141.206897 L36.13,141.206897 L36.13,95.006092 L46.7,88.3850575 L72.81,141.206897 L113.1,141.206897 L72.18,64.9551724 L113.82,3.06471264 L74.8,3.06471264 Z"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
        <rect
          fill="#FFFFFF"
          fillRule="nonzero"
          x="118.12"
          y="3.06471264"
          width="35.86"
          height="138.142184"
        />
        <path
          d="M268.08,20.0722989 C256.553333,8.73390805 240.193333,3.06471264 219,3.06471264 L173.3,3.06471264 L173.3,141.206897 L216,141.206897 C238,141.206897 255.06,134.969387 267.18,122.494368 C279.3,110.019349 285.366667,92.258659 285.38,69.2122989 C285.38,47.7906897 279.613333,31.4106897 268.08,20.0722989 Z M240.75,100.717126 C235.696667,107.306782 227.75,110.598123 216.91,110.591149 L209,110.591149 L209,33.1888506 L219.39,33.1888506 C229.143333,33.1888506 236.413333,36.2431034 241.2,42.3516092 C245.986667,48.4601149 248.38,57.8460153 248.38,70.5093103 C248.353333,84.0651724 245.81,94.1344444 240.75,100.717126 Z"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
        <g transform="translate(293.000000, 0.000000)">
          <text
            fontFamily="SourceSansPro-Bold, Source Sans Pro"
            fontSize="72"
            fontWeight="bold"
            fill="#FFFFFF">
            <tspan x="91" y="159.816092">
              ON
            </tspan>
          </text>
          <rect
            stroke="#FFFFFF"
            strokeWidth="8"
            x="80"
            y="95.183908"
            width="118"
            height="82.6321839"
            rx="10"
          />
          <path
            d="M63,58.3655172 C51.0666667,52.6754023 43.7533333,48.7495019 41.06,46.5878161 C38.37,44.4958621 37.06,41.995977 37.06,39.265977 C37.0655368,38.8313672 37.1023072,38.397808 37.17,37.9689655 C37.5190035,35.9228186 38.6212453,34.101431 40.24,32.895977 C42.36,31.1875479 45.75,30.3333333 50.41,30.3333333 C59.41,30.3333333 70.0766667,33.2934483 82.41,39.2136782 L93.52,10.2401149 C79.2533333,3.62954023 65.2466667,0.324252874 51.5,0.324252874 C35.94,0.324252874 23.71,3.90498084 14.81,11.0664368 C5.91,18.2278927 1.46,28.1995402 1.46,40.9813793 C1.44373476,44.3107736 1.72833347,47.6343247 2.31,50.9077011 C2.79681691,53.601121 3.56128052,56.2314215 4.59,58.7525287 C6.78329756,63.8975103 10.0781727,68.4461377 14.22,72.0468966 C18.5533333,75.9170115 25.0466667,79.9091571 33.7,84.0233333 C43.2666667,88.5210345 49.1566667,91.4950958 51.37,92.9455172 C53.2129078,94.0693085 54.8431973,95.5370248 56.18,97.2758621 C57.185813,98.7199173 57.7125822,100.468387 57.68,102.254713 C57.7144067,104.890811 56.6126356,107.403102 54.68,109.095402 C54.45,109.304598 54.21,109.513793 53.96,109.712529 C51.4733333,111.6441 47.5666667,112.609885 42.24,112.609885 C35.3585705,112.491212 28.5211611,111.436379 21.9,109.471954 C14.5100217,107.412289 7.35245367,104.52984 0.55,100.874023 L0.55,134.345287 C5.81726858,137.019955 11.3327542,139.122897 17.01,140.621149 L19.26,141.154598 C26.5963759,142.575291 34.0499382,143.227023 41.51,143.100115 C52.11,143.100115 61.3433333,141.245249 69.21,137.535517 C75.2629674,134.681083 78.6457605,131.240174 82.52,126.631152 C83.6808695,125.250114 83.1355535,100.561214 84.1008104,99.0226437 C88.3131168,92.1936765 93.6510563,103.551794 93.52,95.4259945 C93.52,86.0540405 91.3266667,81.8755939 86.88,75.6973563 C82.4333333,69.5191188 74.4733333,63.7418391 63,58.3655172 Z"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default KidsModeOn
