import { logout } from 'util/user'
import Bugsnag from '@bugsnag/browser'
import { AxiosError, AxiosRequestConfig } from 'axios'

type Error = AxiosError & { status?: number }

export const logoutInterceptor = {
  onSuccess: (res: AxiosRequestConfig) => res,
  onFailure: (err: AxiosError, client: string) => {
    if ((err.toJSON() as Error).status === 401) {
      Bugsnag.notify(`401 - logout, ${client}`)
      logout()
    }
    return Promise.reject(err)
  },
}
