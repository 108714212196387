import { isSafari } from 'util/Device'
import { licenseApiClient } from 'axiosClients/licenseAPI'

const licenseApi = {
  fetchUpFrontAuthToken: (
    mediaKey: string,
    patronId: string,
    circId: string,
  ) => {
    if (!isSafari()) {
      return licenseApiClient.get(
        `/castlabs/upfront-auth-tokens/${mediaKey}/${patronId}/${circId}`,
      )
    }
  },
  geoCheck: (contentId: string) => {
    return licenseApiClient.get(`/geo/${contentId}`)
  },
  getDownloadKey: (mediaKey: string) => {
    return licenseApiClient.get(`/downloads/book/key/${mediaKey}`)
  },
}

export default licenseApi
