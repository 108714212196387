// REACT IMPORTS
import { MouseEventHandler } from 'react'

// ABSOLUTE IMPORTS
import create, { State } from 'zustand'

export enum MessageLevel {
  error = 'ERROR',
  success = 'SUCCESS',
  info = 'INFO',
}

// if the button has the to prop make it a Link instead of a button.
type MessageButton = {
  text: string
  onClick?: MouseEventHandler
  to?: string
}

export type NotificationMessage = {
  messageLevel: MessageLevel
  messageHeader?: string
  messageBody: string | string[]
  buttons?: MessageButton[]
}

interface NotificationState extends State {
  clearNotification: () => void
  notificationMessage: NotificationMessage | undefined
  setNotification: (notification: NotificationMessage) => void
}

const notificationState = create<NotificationState>((set) => ({
  notificationMessage: undefined,
  clearNotification: () => set({ notificationMessage: undefined }),
  setNotification: (notification) => set({ notificationMessage: notification }),
}))

export const useNotification = (): NotificationState => {
  return {
    clearNotification: notificationState((state) => state.clearNotification),
    notificationMessage: notificationState(
      (state) => state.notificationMessage,
    ),
    setNotification: notificationState((state) => state.setNotification),
  }
}
