import { CreateLaunchDarklyContext } from 'util/launchDarkly'
import { useUser } from 'state/useUser'

import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useLayoutEffect } from 'react'
import { useIntl } from 'react-intl'
import { useKidsMode } from 'state/useKidsMode'
import { useLibrary } from 'state/useLibrary'

// We use a polling method as suggested by LaunchDarkly
// to allow for the features to work properly in the browser
const useLaunchDarklyObserver = () => {
  const locale = useIntl().locale
  const { isKidsMode } = useKidsMode()
  const ldClient = useLDClient()
  const library = useLibrary((state) => state.library)
  const fetchLibrary = useLibrary((state) => state.fetchLibrary)

  // Splitting apart the user context like this to avoid useLayoutEffect object hurdles
  // As well as navigating some typescript undefined property errors
  const { user } = useUser()

  useLayoutEffect(() => {
    if (!user) return

    if (!library) {
      fetchLibrary(user.patrons[0].libraryId)
    }

    const userContextObject = {
      user,
      kidsMode: isKidsMode,
      library,
    }

    const newContext = CreateLaunchDarklyContext(locale, userContextObject)

    if (ldClient) {
      ldClient.identify(newContext)
    } else {
      console.error('LDClient unavailable while updating user context.')
    }
  }, [isKidsMode, ldClient, locale, user, library, fetchLibrary])
}

export default useLaunchDarklyObserver
