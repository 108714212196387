import { hooplaClient } from 'axiosClients/hooplaAPI'
import { playbackClient } from 'axiosClients/playbackAPI'
import { AxiosRequestHeaders } from 'axios'
import UAParser from 'ua-parser-js'

export const api = {
  addPlayCount: (circId: string, contentId: string, segmentId?: string) => {
    const parser = new UAParser()

    const data = {
      circId,
      segmentId,
      source: 'S',
      contentId,
    }

    const headers: AxiosRequestHeaders = {
      os: parser.getOS().name ?? '',
      'os-version': parser.getOS().version ?? '',
      'device-model': parser.getBrowser().name ?? '',
      'device-version': parser.getBrowser().version ?? '',
      'hoopla-version': process.env.REACT_APP_VERSION ?? '',
    }

    return playbackClient.post('/plays/record', data, { headers })
  },
  deleteSession: (
    contentId: string,
    seconds: number,
    percentComplete: number,
  ) => {
    const data = {
      seconds: seconds >= 0 ? Math.floor(seconds) : undefined,
      percentComplete,
    }
    return playbackClient.post(`/sessions/invalidate/${contentId}`, data)
  },
  getPosition: (contentId: string) => {
    return playbackClient.get(`/playback-positions/${contentId}`)
  },
  reportPlayerError: (errorMessage: string, contentId: string) => {
    // Create a new User-agent string parser
    const parser = new UAParser()

    const data = {
      contentId,
      app: 'WWW',
      source: 'STREAM',
      error: 'PLAYER ERROR: ' + errorMessage,
      deviceModel: parser.getBrowser().name,
      deviceVersion: parser.getBrowser().version,
      hooplaVersion: process.env.REACT_APP_VERSION,
      os: parser.getOS().name,
      osVersion: parser.getOS().version,
    }

    hooplaClient.post('/playfailure', data)
  },
  savePosition: (
    contentId: string,
    position: string,
    percentComplete: number,
  ) => {
    const data = {
      position,
      percentComplete:
        percentComplete > -1 ? Math.floor(percentComplete) : undefined,
    }

    return playbackClient.put(`/playback-positions/${contentId}`, data)
  },
  savePositionInSeconds: (
    contentId: string,
    seconds: number,
    percentComplete: number,
  ) => {
    const data = {
      seconds,
      percentComplete:
        percentComplete > -1 ? Math.floor(percentComplete) : undefined,
    }

    return playbackClient.put(`/playback-positions/${contentId}`, data)
  },
  saveSession: (
    contentId: string,
    deviceId: string,
    takeOverSession: boolean,
    seconds: number = 0,
    percentComplete?: number,
  ) => {
    const data = {
      deviceId,
      percentComplete,
      seconds,
      takeOverSession,
    }

    // we use isFinite because we don't want NaN which is a valid number
    if (isFinite(seconds)) {
      data.seconds = Math.floor(seconds)
    }

    return playbackClient.post(`/sessions/${contentId}`, data)
  },
}
