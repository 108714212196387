import { gql } from '@apollo/client'
import { TRACK_FRAGMENT } from 'components/hooks/useTitleDetail'

const TITLE_TEASER_FRAGMENT = gql`
  fragment TitleTeaserFragment on TitleTeaser {
    artKey
    bingePassType
    episodes {
      id
      licenseType
      seasonNumber
      status
      title
    }
    circulation {
      id
    }
    externalCatalogUrl
    hold {
      active
      deleted
      holdEnd
      holdStart
      id
      position
      positionPerCopy
      requestEnd
      requestStart
      reserveUntil
      snoozeUntil
      status
      suspendDays
      suspendUntil
      suspendedPosition
      zombie
    }
    holdsPerCopy
    id
    isFavorite
    kind {
      id
      isEnabled
      name
      plural
      singular
    }
    licenseType
    overlay {
      backColor
      foreColor
      name
    }
    playbackPosition {
      id
      lastPlayed
      percentComplete
    }
    primaryArtist {
      artKey
      bio
      id
      name
    }
    status
    synopsis
    title
    lendingMessage
    titleRating {
      totalCount
      weightedAverage
    }
    zombieHolds
  }
`

export const GET_MINI_TITLE_QUERY = gql`
  query Title($id: ID!) {
    title(criteria: { id: $id, includeDeleted: false }) {
      artKey
      bingePassType
      circulation {
        id
      }
      episodes {
        id
        episode
        licenseType
        status
        title
      }
      hold {
        active
        deleted
        holdEnd
        holdStart
        id
        position
        positionPerCopy
        requestEnd
        requestStart
        reserveUntil
        snoozeUntil
        status
        suspendDays
        suspendUntil
        suspendedPosition
        zombie
      }
      holdsPerCopy
      id
      favorite
      kind {
        id
        enabled
        name
        plural
        singular
      }
      licenseType
      overlay {
        backColor
        foreColor
        name
      }
      playbackPosition {
        id
        lastPlayed
        percentComplete
      }
      primaryArtist {
        artKey
        bio
        id
        name
      }
      season
      status
      synopsis
      title
      titleRating {
        totalCount
        weightedAverage
      }
      tracks {
        ...TrackFragment
      }
      zombieHoldCount
    }
  }
  ${TRACK_FRAGMENT}
`

export const INITIALIZE_HOOTIE = gql`
  query InitializeHootie($input: InitializeHootieSessionInput!) {
    initializeHootieSession(input: $input) {
      bottomMessage
      caseId
      state
      topMessage
    }
  }
`

export const PROMPT_HOOTIE = gql`
  query PromptHootie($input: PromptHootieInput!) {
    promptHootie(input: $input) {
      bottomMessage
      caseId
      source
      state
      topMessage
      titles {
        description
        source
        teaser {
          ...TitleTeaserFragment
        }
      }
    }
  }
  ${TITLE_TEASER_FRAGMENT}
`

export const TITLE_TEASER = gql`
  query TitleTeaser($input: TitleTeaserInput!) {
    titleTeaser(input: $input) {
      ...TitleTeaserFragment
    }
  }
  ${TITLE_TEASER_FRAGMENT}
`
