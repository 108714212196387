import { AxiosRequestHeaders } from 'axios'
import { hooplaClient } from 'axiosClients/hooplaAPI'

interface ValidateLibraryProps {
  libraryId: number
  libraryCard?: string
  libraryPin?: string
  latitude?: number
  longitude?: number
}

export const api = {
  cantFindLibrary: (email: string, zip: string) => {
    return hooplaClient.post(
      '/v1/register/without-library',
      { email, postal: zip },
      { headers: { 'Content-Type': 'application/json' } },
    )
  },
  fetchLibraryForRegistrationId: (registrationUrlId: string) =>
    hooplaClient.get(`/libraries?register-url=${registrationUrlId}`),
  fetchAutocompleteLibraryResults: (query: any) => {
    return hooplaClient.get('/libraries/autocomplete', {
      params: {
        query,
      },
    })
  },
  fetchLibrary: (libraryId: number) =>
    hooplaClient.get(`/libraries/${libraryId}`),
  fetchLibraryRatings: (libraryId: number) =>
    hooplaClient.get(`/libraries/${libraryId}/ratings`),
  fetchLibrarySplash: (libraryId: number) =>
    hooplaClient.get(`/libraries/${libraryId}/splash-config`),
  fetchNearbyLibraries: () => {
    return hooplaClient.get('/libraries/nearby', {
      params: {
        radius: 50,
        limit: 20,
      },
    })
  },
  forgotEmail: (libraryId: number, libraryCard: string) =>
    hooplaClient.get(`/libraries/${libraryId}/card/${libraryCard}/email`),
  listLibrariesForIP: () => {
    return hooplaClient.get('/libraries?for-ip=true')
  },
  validateLibrary: (data: ValidateLibraryProps) => {
    const headers: AxiosRequestHeaders = {
      'Content-Type': 'application/json',
    }

    if (data.latitude && data.longitude) {
      headers['latitude'] = data.latitude
      headers['longitude'] = data.longitude
    }

    return hooplaClient.post('/v1/register/library-check', data, { headers })
  },
}
