import { forwardRef } from 'react'
import classNames from 'classnames'

export default forwardRef(({ hasError, ...props }: any, ref) => {
  const styling = classNames(
    'appearance-none shadow-none box-border m-0 w-full',
    'px-3 py-2',
    'bg-white',
    'border border-secondary-highlight rounded',
    'disabled:text-secondary-highlight',
    'disabled:border-gray-300 disabled:border',
    'disabled:placeholder:text-secondary-highlight',
    { '!border-warning-base !ring-warning-base !outline-none': hasError },
    {
      'outline-0 border-2 border-border-hoopla-blue-light':
        hasError && !props.disabled && (!props.tabIndex as unknown) === -1,
    },
  )

  return (
    <input
      {...props}
      ref={ref}
      className={classNames(styling, props.className)}
    />
  )
})
