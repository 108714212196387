// REACT IMPORTS
import { setCurrentAuthToken } from 'util/user'
import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// ABSOLUTE IMPORTS
import { api as libraryApi } from 'api/librariesApi'
import { api as termsApi } from 'api/termsApi'
import useEvents from 'components/hooks/analytics/useEvents'
import ConnectLibrary from 'components/registration/ConnectLibrary'
import FindLibrary from 'components/registration/FindLibrary'
import Introduction from 'components/registration/Introduction'
import SetupAccount from 'components/registration/SetupAccount'
import { useLogin } from 'state/useLogin'
import create, { SetState, State, GetState } from 'zustand'

type Step = {
  component: JSX.Element
  skipForLibrary: boolean
}
const defaultSteps: Step[] = [
  {
    component: <Introduction />,
    skipForLibrary: false,
  },
  {
    component: <FindLibrary />,
    skipForLibrary: true,
  },
  {
    component: <ConnectLibrary />,
    skipForLibrary: true,
  },
  {
    component: <SetupAccount />,
    skipForLibrary: false,
  },
]

export function useRegistration() {
  // HOOKS
  const { sendRegistrationEvent } = useEvents()
  const location: any = useLocation()
  const { initializePatron } = useLogin()
  const navigate = useNavigate()

  // STATE
  const formData = useRegistrationState((state) => state.formData)
  const loading = useRegistrationState((state) => state.loading)
  const setFormData = useRegistrationState((state) => state.setFormData)
  const setStep = useRegistrationState((state) => state.setStep)
  const steps = useRegistrationState((state) => state.steps)
  const step = useRegistrationState((state) => state.step)
  const stepCount = useRegistrationState((state) => state.stepCount)
  const setupSteps = useRegistrationState((state) => state.setupSteps)
  const registrationSuccess = useRegistrationState(
    (state) => state.registrationSuccess,
  )
  const setRegistrationSuccess = useRegistrationState(
    (state) => state.setRegistrationSuccess,
  )
  const teardown = useRegistrationState((state) => state.teardown)

  const onNext: (data?: RegistrationFormData) => void = useCallback(
    async function (data?: RegistrationFormData) {
      if (step < steps.length - 1) {
        setStep(step + 1)
      } else {
        setRegistrationSuccess(true)
      }
      if (data) {
        setFormData(data)
      }
    },
    [setFormData, setRegistrationSuccess, setStep, step, steps.length],
  )

  const onBack: (data?: RegistrationFormData) => void = useCallback(
    function (data?: RegistrationFormData) {
      sendRegistrationEvent({ label: 'back' })

      if (step > 0) {
        setStep(step - 1)
      } else {
        if (location?.state?.prevLocation) {
          navigate(location?.state?.prevLocation, { replace: true })
        } else {
          navigate(-1)
        }
      }
      if (data) {
        setFormData(data)
      }
    },
    [
      location?.state?.prevLocation,
      navigate,
      sendRegistrationEvent,
      setFormData,
      setStep,
      step,
    ],
  )

  const setupRegistration = useCallback(
    async function (libraryUrlRegistrationId?: string) {
      // 1. check for url/IP auth
      let library: any
      if (libraryUrlRegistrationId) {
        const response = await libraryApi.fetchLibraryForRegistrationId(
          libraryUrlRegistrationId,
        )
        const libraries = response?.data
        if (libraries?.[0]) {
          library = libraries?.[0]
        }
      } else {
        const response = await libraryApi.listLibrariesForIP()
        const libraries = response?.data
        if (libraries?.[0]) {
          library = libraries?.[0]
        }
      }

      // 2. generate a list of steps
      // 3. step count and position
      if (library) {
        // If we've found a library for URL or IP we can skip the library selection steps
        setFormData({ library })
        setupSteps(defaultSteps.filter((s) => !s?.skipForLibrary))
      } else {
        setupSteps(defaultSteps)
      }
    },
    [setFormData, setupSteps],
  )

  const loginUser = useCallback(
    async (authToken: string) => {
      setCurrentAuthToken(true, authToken)

      // we are noting that the user has accepted the most recent terms
      const termsResponse = await termsApi.getTerms()
      await termsApi.postTerms(authToken, termsResponse.data.id)

      await initializePatron()
    },
    [initializePatron],
  )

  return {
    setupRegistration,
    formData,
    setFormData,
    steps,
    step,
    stepCount,
    loading,
    registrationSuccess,
    onNext,
    onBack,
    loginUser,
    teardown,
  }
}

interface RegistrationState extends State {
  formData: RegistrationFormData
  loading: boolean
  registrationSuccess: boolean
  setFormData: (formData: RegistrationFormData) => void
  setRegistrationSuccess: (registrationSuccess: boolean) => void
  steps: Step[]
  step: number
  stepCount: number
  setStep: (step: number) => void
  setupSteps: (steps: Step[]) => void
  teardown: () => void

  showForgotEmailModal: boolean
  showForgotPasswordModal: boolean
  setShowForgotEmailModal: (show: boolean) => void
  setShowForgotPasswordModal: (show: boolean) => void
}

export const useRegistrationState = create(
  (
    set: SetState<RegistrationState>,
    _get: GetState<RegistrationState>,
  ): RegistrationState => ({
    formData: {},
    loading: true,
    registrationSuccess: false,
    setFormData: (formData: RegistrationFormData) =>
      set((state) => ({ formData: { ...state.formData, ...formData } })),
    setRegistrationSuccess: (registrationSuccess: boolean) =>
      set({ registrationSuccess }),
    setStep: (step: number) => set({ step }),
    setupSteps: (steps: Step[]) =>
      set({ steps, stepCount: steps.length, loading: false }),
    steps: [],
    step: 0,
    stepCount: 1,
    teardown: () =>
      set({
        step: 0,
        stepCount: 1,
        steps: [],
        loading: true,
        registrationSuccess: false,
        formData: {},
      }),

    showForgotEmailModal: false,
    showForgotPasswordModal: false,
    setShowForgotEmailModal: (show: boolean) =>
      set({ showForgotEmailModal: show, showForgotPasswordModal: false }),
    setShowForgotPasswordModal: (show: boolean) =>
      set({ showForgotEmailModal: false, showForgotPasswordModal: show }),
  }),
)
