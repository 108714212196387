import { MouseEvent } from 'react'
import classNames from 'classnames'

const className = classNames(
  'inline-block rounded-md disabled:cursor-not-allowed',
  'transition-colors py-3 px-8 h-auto w-auto leading-none',
  'bg-red-500 hover:bg-red-700 focus:bg-red-700 disabled:opacity-50',
  'text-xs tracking-wider text-white text-center uppercase font-semibold hover:text-white focus:text-white',
)

interface WarningButtonProps {
  children: JSX.Element
  onClick: (e: MouseEvent) => void
}

export function WarningButton({ children, onClick }: WarningButtonProps) {
  return (
    <button className={className} onClick={onClick}>
      {children}
    </button>
  )
}
