import { defaultIconProps, IconWeight } from 'components/common/icons'

export const ChevronRight = ({
  size = defaultIconProps.size,
  className = defaultIconProps.className,
  weight = defaultIconProps.weight,
}: DefaultIconProps) => {
  if (weight === IconWeight.light) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        className={className}>
        <polyline points="9 18 15 12 9 6"></polyline>
      </svg>
    )
  }

  if (weight === IconWeight.regular) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="currentColor"
        className={className}>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
        />
      </svg>
    )
  }

  if (weight === IconWeight.heavy) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
        width={size}
        height={size}
        fill="currentColor"
        stroke="currentColor"
        className={className}>
        <path d="M285.476 272.971 91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
      </svg>
    )
  }

  return <></>
}
