import { ReactNode } from 'react'
import { useRegistrationState } from 'state/useRegistration'
import { FormattedMessage } from 'react-intl'
import BackArrow from 'components/common/icons/BackArrow'
interface AuthLayoutProps {
  children: ReactNode
}

export function AuthLayout({ children }: AuthLayoutProps) {
  return (
    <div
      className="fixed inset-0 flex h-full w-full items-center justify-center overflow-y-auto bg-cover bg-center"
      style={{
        backgroundImage: `linear-gradient(225deg, rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.55)), url('https://image.hoopladigital.com/www/home/banner.jpg')`,
      }}>
      <div className="m-auto h-[42rem] w-full max-w-[30rem] bg-white py-4 px-3 shadow sm:rounded-xl sm:py-10 sm:px-6">
        {children}
      </div>
    </div>
  )
}

interface StepperProps {
  onBack: () => void
}

export function Stepper({ onBack }: StepperProps) {
  const step = useRegistrationState((state) => state.step)
  const stepCount = useRegistrationState((state) => state.stepCount)

  return (
    <div className="mb-4 flex w-full flex-row items-center justify-center">
      <button onClick={() => onBack()} className="flex-1" type="button">
        <BackArrow className="fill-hoopla-blue text-hoopla-blue" size={28} />
      </button>
      <span className="flex-auto text-center font-semibold text-gray-500">
        <FormattedMessage
          id="registration.stepper.message"
          defaultMessage="Step {step} of {stepCount}"
          values={{ step: step + 1, stepCount }}
        />
      </span>
      <span className="w-[28px] flex-1" />
    </div>
  )
}
