import { defaultIconProps } from 'components/common/icons'

export const Hootie = ({
  size = defaultIconProps.size,
  className = defaultIconProps.className,
}: DefaultIconProps) => (
  <div
    className={className}
    style={{
      zIndex: 1,
      backgroundImage:
        'url(https://web.hoopladigital.com/hootie/hootieicon.png)',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: size,
      height: size,
    }}></div>
)
