import { getCurrentAuthToken } from 'util/user'
import axios from 'axios'
import config from 'config'
import { logoutInterceptor } from './util'

const licenseConfig = {
  baseURL: `${config.patronApiGateway}/license`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'binge-pass-external-enabled': process.env.REACT_APP_BINGE_PASS_ENABLED,
  },
  transformRequest: [
    function (data) {
      var str = []
      for (let p in data)
        if (data[p]) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(data[p]))
        }
      return str.join('&')
    },
  ],
}

export const licenseClient = axios.create(licenseConfig)

export const licenseOptions = {
  returnRejectedPromiseOnError: true,
  interceptors: {
    request: [
      (_, config) => {
        const token = getCurrentAuthToken()
        if (token) {
          config.headers['Authorization'] = 'Bearer ' + token
        }

        return config
      },
    ],
  },
}

const authorizationInterceptor = (config) => {
  const token = getCurrentAuthToken()

  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }
  return config
}

export const licenseApiClient = axios.create(licenseConfig)

licenseApiClient.interceptors.request.use(authorizationInterceptor)
licenseApiClient.interceptors.response.use(logoutInterceptor.onSuccess, (err) =>
  logoutInterceptor.onFailure(err, 'licenseAPI'),
)
