import { CreateLaunchDarklyProvider } from 'util/launchDarkly'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'
import { client } from 'graphql/client'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { IntlProvider } from 'react-intl'
import { BrowserRouter } from 'react-router-dom'
import { App } from './app'

import messages from './locale-data/messages'
import { initializeFirebase } from './util/firebase'
;(async () => {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_KEY,
    appVersion: process.env.REACT_APP_VERSION,
    plugins: [new BugsnagPluginReact()],
    releaseStage:
      window.location.origin.indexOf('localhost') >= 0
        ? 'localhost'
        : process.env.REACT_APP_ENV,
    enabledReleaseStages: ['staging', 'production', 'localhost'],
    onError: (event) => {
      if (event.app.releaseStage === 'localhost') {
        console.log('bugsnag error', event.errors[0]) // or whatever is of interest about the event
        return false // discard the error
      }
    },
  })

  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

  initializeFirebase()

  let locale = navigator.languages
    ? navigator.languages[0]
    : navigator.language || navigator.userLanguage // will give you en-US or fr-CA, etc.
  locale = locale ? locale.split('-')[0] : 'en'

  const LaunchDarklyProvider = await CreateLaunchDarklyProvider(locale)

  const root = ReactDOM.createRoot(document.getElementById('app'))

  root.render(
    <ErrorBoundary>
      <IntlProvider
        locale={locale}
        messages={messages[locale]}
        defaultLocale="en">
        <ApolloProvider client={client}>
          <LaunchDarklyProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </LaunchDarklyProvider>
        </ApolloProvider>
      </IntlProvider>
    </ErrorBoundary>,
  )
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
