import { getCurrentAuthToken } from 'util/user'
import axios from 'axios'
import config from 'config'
import { logoutInterceptor } from './util'

export const playbackClient = axios.create({
  baseURL: `${config.patronApiGateway}/play`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'binge-pass-external-enabled': process.env.REACT_APP_BINGE_PASS_ENABLED,
  },
  transformRequest: [
    function (data) {
      var str = []
      for (let p in data)
        if (
          Object.prototype.hasOwnProperty.call(data, p) &&
          data[p] !== null &&
          data[p] !== undefined
        ) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(data[p]))
        }
      return str.join('&')
    },
  ],
})

playbackClient.interceptors.request.use(function (config) {
  const token = getCurrentAuthToken()
  if (token) {
    config.headers.authorization = `Bearer ${token}`
  }

  return config
})

export const playbackOptions = {
  returnRejectedPromiseOnError: true,
}

playbackClient.interceptors.response.use(logoutInterceptor.onSuccess, (err) =>
  logoutInterceptor.onFailure(err, 'playbackAPI'),
)
