import { getCurrentAuthToken, logout } from 'util/user'
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import Bugsnag from '@bugsnag/js'

import config from 'config'

const hooplaLink = createHttpLink({
  uri: `${config.patronApiGateway}/graphql`,
})

const hootieLink = createHttpLink({
  uri: `${config.patronApiGateway}/hootie`,
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, query }) => {
      Bugsnag.notify(new Error(message), function (event) {
        event.addMetadata('query', query)
      })
    })
  }

  if (networkError) {
    if (networkError.statusCode === 401) {
      // The current user is authorized. Lets clear credentials and logout
      logout()
    }
    Bugsnag.notify(networkError)
  }
})
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getCurrentAuthToken()
  // return the headers to the context so httpLink can read them

  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `Bearer ${token}` } : undefined),
      'binge-pass-external-enabled': true,
      'binge-pass-internal-enabled': true,
      'external-promos-enabled': true,
      'traditional-manga-enabled': true,
    },
  }
})

const link = ApolloLink.from([
  authLink,
  errorLink,
  errorLink,
  ApolloLink.split(
    (operation) => operation.getContext().clientName === 'HOOTIE',
    hootieLink,
    hooplaLink,
  ),
])

export const client = new ApolloClient({
  name: 'hoopla-www',
  version: process.env.REACT_APP_VERSION,
  link,
  cache: new InMemoryCache({
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
    typePolicies: {
      // NOTE: most of these policies where the incoming is blindly taken
      // are due to kids mode. e.g. without kids mode, a title is defined but
      // kids mode it is not defined. this leads to apollo not knowing what to do
      // to reconcile the caches.
      Query: {
        fields: {
          currentlyBorrowed: {
            merge(existing, incoming) {
              return incoming
            },
          },
          genres: {
            merge(existing, incoming) {
              return incoming
            },
          },
          kinds: {
            // blindly merging here because a kind can be enabled / disabled by the library,
            // and going from unauthenticated to authenticated causes difficulties in
            // apollo with caches.
            merge(existing, incoming) {
              return incoming
            },
          },
          remainingBorrows: {
            merge(existing, incoming) {
              return incoming
            },
          },
          bookmarks: {
            merge(existing, incoming) {
              return incoming
            },
          },
          highlights: {
            merge(existing, incoming) {
              return incoming
            },
          },
        },
      },
      Genre: {
        fields: {
          children: {
            merge(existing, incoming) {
              return incoming
            },
          },
        },
      },
    },
  }),
})
