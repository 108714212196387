// REACT IMPORTS
import { isLoggedIn } from 'util/user'
import { defineMessages, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

// ABSOLUTE IMPORTS
import { useKidsMode } from 'state/useKidsMode'
import { useCallback } from 'react'
import KidsModeOn from './KidsModeOn'
import KidsModeOff from './KidsModeOff'

const messages: IntlMessage = defineMessages({
  enabled: {
    id: 'kidsmode.enabled',
    defaultMessage: 'Kids mode enabled.',
  },
  disabled: {
    id: 'kidsmode.disabled',
    defaultMessage: 'Kids mode disabled.',
  },
})

export default function KidsModeIcon() {
  const isKidsMode = useKidsMode((state) => state.isKidsMode)
  const setKidsMode = useKidsMode((state) => state.setKidsMode)

  const { formatMessage } = useIntl()
  const isAuthenticated = isLoggedIn()
  const toggleKidsMode = useCallback(() => {
    setKidsMode({ isKidsMode: !isKidsMode })
  }, [setKidsMode, isKidsMode])

  if (isAuthenticated) {
    return (
      <Link
        to="/my/settings/kids-mode"
        aria-label={
          isKidsMode
            ? formatMessage(messages.enabled)
            : formatMessage(messages.disabled)
        }
        className="block">
        {isKidsMode ? <KidsModeOn /> : <KidsModeOff />}
      </Link>
    )
  } else {
    return (
      <button
        onClick={toggleKidsMode}
        aria-label={
          isKidsMode
            ? formatMessage(messages.enabled)
            : formatMessage(messages.disabled)
        }>
        {isKidsMode ? <KidsModeOn /> : <KidsModeOff />}
      </button>
    )
  }
}
