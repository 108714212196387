import { FormattedMessage } from 'react-intl'
import { WarningButton } from 'ui/buttons/WarningButton'
import { PrimaryButton } from 'ui/buttons/PrimaryButton'
import { AuthLayout } from 'components/registration/ui'

interface ConfirmModalProps {
  onClose: () => void
  onContinue: () => void
}

export default function ConfirmModal({
  onContinue,
  onClose,
}: ConfirmModalProps) {
  return (
    <AuthLayout>
      <div className="flex h-full flex-col justify-between">
        <div>
          <h3 className="text-2xl md:text-3xl">
            <FormattedMessage
              id="terms.confirm.header"
              defaultMessage="Are you sure you want to decline?"
            />
          </h3>

          <p className="mt-2">
            <FormattedMessage
              id="terms.confirm.message"
              defaultMessage="You will be unable to borrow or play content without agree to the new terms."
            />
          </p>
        </div>

        <div className="flex flex-row items-center justify-between space-x-2">
          <WarningButton onClick={onClose}>
            <FormattedMessage
              id="terms.confirm.decline"
              defaultMessage="Decline terms"
            />
          </WarningButton>

          <PrimaryButton onClick={onContinue}>
            <FormattedMessage
              id="terms.confirm.continue"
              defaultMessage="Continue to terms"
            />
          </PrimaryButton>
        </div>
      </div>
    </AuthLayout>
  )
}
