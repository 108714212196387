import { useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import { WarningButton } from 'ui/buttons/WarningButton'
import { PrimaryButton } from 'ui/buttons/PrimaryButton'
import { AuthLayout } from 'components/registration/ui'

function applyClasses(ref: HTMLElement) {
  if (ref) {
    ref.querySelectorAll('p').forEach((el: HTMLElement) => {
      el.classList.add('mb-2')
    })

    ref.querySelectorAll('h2').forEach((el: HTMLElement) => {
      el.classList.add('font-bold')
      el.classList.add('text-3xl')
      el.classList.add('mb-4')
    })

    ref.querySelectorAll('h3').forEach((el: HTMLElement) => {
      el.classList.add('font-semibold')
      el.classList.add('text-xl')
      el.classList.add('mb-2')
    })

    ref.querySelectorAll('a').forEach((el: HTMLElement) => {
      el.classList.add('text-hoopla-blue')
      el.classList.add('underline')
    })
  }
}

interface TermsModalProps {
  onAccept: () => void
  onDecline: () => void
  terms: string
}

export default function TermsModal({
  terms,
  onDecline,
  onAccept,
}: TermsModalProps) {
  const termsRef = useRef(null)

  useEffect(() => {
    if (terms && termsRef.current) {
      applyClasses(termsRef.current)
    }
  }, [terms])

  return (
    <AuthLayout>
      <div className="flex h-full w-full flex-col">
        <div
          className="mb-6 overflow-y-scroll pb-10"
          ref={termsRef}
          dangerouslySetInnerHTML={{ __html: terms }}
        />

        <div className="flex justify-between">
          <WarningButton onClick={onDecline}>
            <FormattedMessage
              id="terms.decline.button"
              defaultMessage="Decline"
            />
          </WarningButton>

          <PrimaryButton onClick={onAccept}>
            <FormattedMessage
              id="terms.accept.button"
              defaultMessage="Accept"
            />
          </PrimaryButton>
        </div>
      </div>
    </AuthLayout>
  )
}
