import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'

let firebaseApp

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

export const initializeFirebase = () => {
  // Initialize Firebase
  try {
    firebaseApp = initializeApp(firebaseConfig)
  } catch {
    // no one cares about this enough to track or log this error
  }
}

export const logPageView = (url = window.location.pathname) => {
  try {
    const analytics = getAnalytics(firebaseApp)

    logEvent(analytics, 'screen_view', {
      firebase_screen: url,
    })
  } catch {
    // no one cares about this enough to track or log this error
  }
}
