import classNames from 'classnames'

interface FormLabelProps {
  children: JSX.Element | string
  className?: string
  disabled?: boolean
  hidden?: boolean
  htmlFor: string
}

export const FormLabel = ({
  children,
  className = '',
  hidden = false,
  disabled = false,
  htmlFor,
}: FormLabelProps) => {
  const classes = classNames(
    'mb-1',
    className,
    { 'sr-only': hidden },
    { 'opacity-50': disabled },
  )
  return (
    <label className={classes} htmlFor={htmlFor}>
      {children}
    </label>
  )
}

export const FormError = ({ children }: { children: string }) => {
  return (
    <span className="mt-1 block text-sm leading-tight text-red-500">
      {children}
    </span>
  )
}

export const FormGroup = ({
  children,
  className = '',
}: {
  children: JSX.Element[]
  className?: string
}) => {
  return <div className={classNames('mb-4 w-full', className)}>{children}</div>
}
