import english from './en-US.json'
import french from './fr-CA.json'
import spanish from './es-MX.json'

const messages = {
  en: english,
  fr: french,
  es: spanish,
}

export default messages
