import { Helmet } from 'react-helmet'
import config from 'config'

const GtmInitScript = () => {
  return (
    <Helmet>
      <script>
        {`
        ;(function (w, d, s, l, i) {
          w[l] = w[l] || []
          w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : ''
          j.async = true
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
          f.parentNode.insertBefore(j, f)
        })(window, document, 'script', 'dataLayer', '${config.gtmTrackingId}')
        `}
      </script>
      <body>
        {`
        <noscript
          ><iframe
            src="https://www.googletagmanager.com/ns.html?id='${config.gtmTrackingId}'"
            height="0"
            width="0"
            style="display: none; visibility: hidden"></iframe></noscript
        > `}
      </body>
    </Helmet>
  )
}

export default GtmInitScript
