import { defaultIconProps, IconWeight } from 'components/common/icons'

export const ChevronLeft = ({
  size = defaultIconProps.size,
  className = defaultIconProps.className,
  weight = defaultIconProps.weight,
}: DefaultIconProps) => {
  if (weight === IconWeight.light) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        className={className}>
        <polyline points="15 18 9 12 15 6"></polyline>
      </svg>
    )
  } else if (weight === IconWeight.regular) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="currentColor"
        className={className}>
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
      </svg>
    )
  } else {
    return <></>
  }
}
