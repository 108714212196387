import axios from 'axios'
import config from 'config'
import { logoutInterceptor } from './util'

export const apiGatewayClient = axios.create({
  baseURL: config.apiGatewayAPI,
  withCredentials: false,
})

apiGatewayClient.interceptors.response.use(logoutInterceptor.onSuccess, (err) =>
  logoutInterceptor.onFailure(err, 'apiGatewayAPI'),
)
