import { useCallback, useEffect, useState } from 'react'

import { api } from 'api/termsApi'
import { useLogout } from 'state/useLogout'
import { useUser } from 'state/useUser'
import { AxiosResponse } from 'axios'
import TermsModal from './TermsModal'
import ConfirmModal from './ConfirmModal'

interface TermsOfServiceProps {
  children: JSX.Element
}

export type Terms = {
  agreed: boolean
  html: string
  id: string
}

export const TermsOfService = ({ children }: TermsOfServiceProps) => {
  const user = useUser((state) => state.user)
  const [isShowingTerms, setIsShowingTerms] = useState(false)
  const [isShowingConfirmation, setIsShowingConfirmation] = useState(false)
  const [terms, setTerms] = useState<Terms>()
  const { completeLogout } = useLogout()

  const getTerms = useCallback(async () => {
    const response: AxiosResponse<Terms> = await api.getTerms(user?.authToken)

    if (!response.data.agreed) {
      setTerms(response.data)
      setIsShowingTerms(true)
    }
  }, [user])

  useEffect(() => {
    if (user?.authToken) {
      getTerms()
    }

    return () => {
      setTerms(undefined)
    }
  }, [getTerms, user])

  const onAccept = async () => {
    if (user?.authToken && terms?.id) {
      await api.postTerms(user.authToken, terms.id)
      setIsShowingTerms(false)
    }
  }

  const onCloseTerms = useCallback(() => {
    // logout and send to home page
    setIsShowingTerms(false)
    setIsShowingConfirmation(false)
    completeLogout()
  }, [completeLogout])

  if (isShowingConfirmation) {
    return (
      <ConfirmModal
        onClose={onCloseTerms}
        onContinue={() => setIsShowingConfirmation(false)}
      />
    )
  } else if (isShowingTerms && terms) {
    return (
      <TermsModal
        terms={terms.html}
        onAccept={onAccept}
        onDecline={() => setIsShowingConfirmation(true)}
      />
    )
  } else {
    return children
  }
}
