import { Link, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import capitalize from 'lodash/capitalize'

export type MenuItem = {
  ariaLabel?: string
  current: boolean
  label: string // i18n
  to: string
}

interface InlineMenuProps {
  children?: JSX.Element
  MenuItems: MenuItem[]
}

export default function InlineMenu({ children, MenuItems }: InlineMenuProps) {
  const navigate = useNavigate()

  const getLink = (selectedLabel: string) => {
    return MenuItems.find(({ label }) => label === selectedLabel)?.to || ''
  }

  return (
    <div className="my-2 border-t border-b border-solid border-gray-200 py-1">
      <div className="sm:hidden">
        <select
          id="menu"
          name="menu"
          className="block w-full rounded-md border-gray-300"
          defaultValue={MenuItems.find(({ current }) => current)?.label}
          onChange={(e) => navigate(getLink(e.target.value))}>
          {MenuItems.map(({ ariaLabel, label }) => {
            return (
              <option aria-label={ariaLabel} key={label} value={label}>
                {capitalize(label)}
              </option>
            )
          })}
        </select>
      </div>

      <div className="hidden sm:flex sm:items-center sm:justify-between">
        <nav className="flex flex-none space-x-4" aria-label="Menu">
          {MenuItems.map(({ ariaLabel, label, to, current }) => (
            <Link
              aria-label={ariaLabel}
              key={label}
              to={to}
              className={classNames(
                current
                  ? 'bg-hoopla-blue/20 text-hoopla-blue-dark'
                  : 'text-gray-500 hover:text-gray-700',
                'flex-none rounded-md px-2 py-1 text-sm font-medium capitalize hover:bg-gray-300 active:bg-hoopla-blue active:text-white',
              )}
              aria-current={current ? 'page' : undefined}>
              {label}
            </Link>
          ))}
        </nav>

        {children}
      </div>
    </div>
  )
}
