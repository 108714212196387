import { useCallback, useRef } from 'react'
import ReactHelmet from 'react-helmet'
import { useMount, useUnmount } from 'react-use'

const sliceDescription = (desc: string) => {
  if (desc?.length >= 155) {
    return desc.slice(0, 154) + '…'
  } else return desc
}

export function Helmet({ ...delegated }) {
  const prerenderTimer = useRef<number | null | ReturnType<typeof setTimeout>>(
    null,
  )

  const notifyPrerender = useCallback(
    function notifyPrerender() {
      // we need to tell prerender we are ready but we want to make sure the page gets a chance to render
      if (!prerenderTimer.current) {
        prerenderTimer.current = setTimeout(() => {
          if (!window?.prerenderReady) {
            window.prerenderReady = true

            prerenderTimer.current = null
          }
        }, 10000)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.prerenderReady],
  )

  useMount(() => {
    notifyPrerender()
  })

  useUnmount(() => {
    prerenderTimer.current && clearTimeout(prerenderTimer.current as number)
  })

  // if a description for a meta item is 155 characters or more, we want to condense it down to 154 characters with an elipses and | hoopla
  if (delegated.meta) {
    if (Array.isArray(delegated.meta)) {
      delegated.meta = delegated.meta.map((item: any) => {
        if (
          item?.name?.includes('description') ||
          item?.property?.includes('description')
        ) {
          return { ...item, content: sliceDescription(item.content) }
        } else return item
      })
    } else {
      delegated.meta = {
        ...delegated.meta,
        content: sliceDescription(delegated.meta.content),
      }
    }
  }

  return <ReactHelmet {...delegated} />
}
