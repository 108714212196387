import { fetchCurrentUser } from 'util/user'

import { asyncWithLDProvider, basicLogger } from 'launchdarkly-react-client-sdk'

type HooplaLDUserContext =
  | {
      key: string
      libraryId: number
      kidsMode: boolean
      libraryCountryId: number
    }
  | undefined

type HooplaLDMultiContext = {
  kind: 'multi'
  user: HooplaLDUserContext
  device: {
    key: string
    language: string
  }
  app: {
    key: string
    app: string
    version: string
  }
}

export const CreateLaunchDarklyProvider = async (locale: string) => {
  const LAUNCH_DARKLY_CLIENT_SIDE_KEY =
    process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_KEY

  if (!LAUNCH_DARKLY_CLIENT_SIDE_KEY) {
    throw new Error('LAUNCH_DARKLY_CLIENT_SIDE_KEY is not set')
  }

  const user = await fetchCurrentUser()
  const ldContext = CreateLaunchDarklyContext(locale, {
    user,
    kidsMode: false,
    library: undefined,
  })

  const logging = () => {
    return process.env.REACT_APP_ENV === ('staging' || 'development')
      ? basicLogger({ level: 'debug' })
      : undefined
  }

  const launchDarklyProvider = await asyncWithLDProvider({
    clientSideID: LAUNCH_DARKLY_CLIENT_SIDE_KEY,
    context: ldContext,
    options: {
      logger: logging(),
    },
    deferInitialization: true,
  })

  return launchDarklyProvider
}

export const CreateLaunchDarklyContext = (
  locale: string,
  userContext: {
    user?: User
    kidsMode?: boolean
    library?: Library
  },
): HooplaLDMultiContext => {
  const {
    REACT_APP_NAME = 'hoopla-www',
    REACT_APP_VERSION = 'unknown.version.check.env',
  } = process.env

  return {
    kind: 'multi',
    user: CreateLaunchDarklyUserContext(
      userContext.user,
      userContext.kidsMode,
      userContext.library,
    ),
    device: {
      key: locale,
      language: locale,
    },
    app: {
      key: `${REACT_APP_NAME}-${REACT_APP_VERSION}`,
      app: REACT_APP_NAME,
      version: REACT_APP_VERSION,
    },
  }
}

export const CreateLaunchDarklyUserContext = (
  user?: User,
  kidsMode = false,
  library?: Library,
): HooplaLDUserContext => {
  if (!user || !library) return undefined
  return {
    key: user.id,
    libraryId: user.patrons[0].libraryId,
    kidsMode,
    libraryCountryId: library.countryId,
  }
}
