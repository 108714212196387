const KidsModeOff = () => (
  <svg
    width="76px"
    height="25px"
    viewBox="0 0 524 174"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <path
          d="M74.8,2.93 L47.06,43.4 C41.58,51.1333333 37.9366667,56.7666667 36.13,60.3 L36.13,2.93 L0.27,2.93 L0.27,135 L36.13,135 L36.13,90.83 L46.7,84.5 L72.81,135 L113.1,135 L72.18,62.1 L113.82,2.93 L74.8,2.93 Z"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
        <rect
          fill="#FFFFFF"
          fillRule="nonzero"
          x="118.12"
          y="2.93"
          width="35.86"
          height="132.07"
        />
        <path
          d="M268.08,19.19 C256.553333,8.35 240.193333,2.93 219,2.93 L173.3,2.93 L173.3,135 L216,135 C238,135 255.06,129.036667 267.18,117.11 C279.3,105.183333 285.366667,88.2033333 285.38,66.17 C285.38,45.69 279.613333,30.03 268.08,19.19 Z M240.75,96.29 C235.696667,102.59 227.75,105.736667 216.91,105.73 L209,105.73 L209,31.73 L219.39,31.73 C229.143333,31.73 236.413333,34.65 241.2,40.49 C245.986667,46.33 248.38,55.3033333 248.38,67.41 C248.353333,80.37 245.81,89.9966667 240.75,96.29 Z"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
        <g transform="translate(293.000000, 0.000000)">
          <text
            fontFamily="SourceSansPro-Bold, Source Sans Pro"
            fontSize="72"
            fontWeight="bold"
            fill="#FFFFFF">
            <tspan x="91" y="154">
              OFF
            </tspan>
          </text>
          <rect
            stroke="#FFFFFF"
            strokeWidth="8"
            x="80"
            y="91"
            width="147"
            height="79"
            rx="10"
          />
          <path
            d="M63,55.8 C51.0666667,50.36 43.7533333,46.6066667 41.06,44.54 C38.37,42.54 37.06,40.15 37.06,37.54 C37.0655368,37.1244939 37.1023072,36.7099922 37.17,36.3 C37.5190035,34.3437936 38.6212453,32.602467 40.24,31.45 C42.36,29.8166667 45.75,29 50.41,29 C59.41,29 70.0766667,31.83 82.41,37.49 L93.52,9.79 C79.2533333,3.47 65.2466667,0.31 51.5,0.31 C35.94,0.31 23.71,3.73333333 14.81,10.58 C5.91,17.4266667 1.46,26.96 1.46,39.18 C1.44373476,42.3630473 1.72833347,45.5405082 2.31,48.67 C2.79681691,51.2450277 3.56128052,53.7597107 4.59,56.17 C6.78329756,61.0888285 10.0781727,65.4375162 14.22,68.88 C18.5533333,72.58 25.0466667,76.3966667 33.7,80.33 C43.2666667,84.63 49.1566667,87.4733333 51.37,88.86 C53.2129078,89.9343938 54.8431973,91.3375951 56.18,93 C57.185813,94.3805803 57.7125822,96.0521943 57.68,97.76 C57.7144067,100.280226 56.6126356,102.682087 54.68,104.3 C54.45,104.5 54.21,104.7 53.96,104.89 C51.4733333,106.736667 47.5666667,107.66 42.24,107.66 C35.3585705,107.546544 28.5211611,106.538076 21.9,104.66 C14.5100217,102.69087 7.35245367,99.9351221 0.55,96.44 L0.55,128.44 C5.81726858,130.9971 11.3327542,133.007605 17.01,134.44 L19.26,134.95 C26.5963759,136.308245 34.0499382,136.93133 41.51,136.81 C52.11,136.81 61.3433333,135.036667 69.21,131.49 C75.2629674,128.761035 78.6457605,125.471375 82.52,121.064948 C83.6808695,119.744614 83.1355535,96.1409408 84.1008104,94.67 C88.3131168,88.1412072 93.6510563,99.0000671 93.52,91.2314453 C93.52,82.2714453 91.3266667,78.2766667 86.88,72.37 C82.4333333,66.4633333 74.4733333,60.94 63,55.8 Z"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default KidsModeOff
