import { gql } from '@apollo/client'
import { client } from 'graphql/client'
import keyBy from 'lodash/keyBy'
import create, { SetState, State } from 'zustand'

interface LanguageState extends State {
  languages: Language[]
  languagesById: { [key: string]: Language }
  getLanguages: () => void
}

const LANGUAGE_QUERY = gql`
  query {
    languages {
      id
      label
    }
  }
`

export const useLanguages = create(
  (set: SetState<LanguageState>): LanguageState => ({
    languagesById: {},
    languages: [],
    getLanguages: async () => {
      const { data } = await client.query({
        query: LANGUAGE_QUERY,
        fetchPolicy: 'cache-first',
      })
      const languages = data.languages
      set({ languages, languagesById: keyBy(languages, 'id') })
    },
  }),
)
