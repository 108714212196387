import classNames from 'classnames'

interface LayoutContainerProps {
  children: JSX.Element | JSX.Element[]
  className?: string
}

export function LayoutContainer({ children, className }: LayoutContainerProps) {
  return (
    <div
      className={classNames(
        className,
        'mx-auto w-95vw md:max-w-245 lg:w-full',
      )}>
      {children}
    </div>
  )
}
