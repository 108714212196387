import { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { PrimaryButton } from 'ui/buttons/PrimaryButton'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log('hopefully a syntax error', error, error?.name)

    if (error?.name === 'ChunkLoadError' || error?.name === 'SyntaxError') {
      return { hasError: true }
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="t-0 r-0 absolute z-10 flex h-full w-full items-center justify-center bg-hoopla-blue-dark">
          <div className="relative z-20 max-w-md space-y-4 rounded-md bg-white py-10 px-6 text-center shadow-xl">
            <h2 className="text-3xl">
              <FormattedMessage
                id="update.modal.heading"
                defaultMessage="Refresh Required"
              />
            </h2>
            <p className="mb-4">
              <FormattedMessage
                id="update.modal.message"
                defaultMessage="We have released a new version of the hoopla web site.  You'll need to refresh this page now to continue."
              />
            </p>
            <div className="flex justify-center">
              <PrimaryButton onClick={() => window.location.reload(true)}>
                <FormattedMessage
                  id="update.modal.button"
                  defaultMessage="Refresh now"
                />
              </PrimaryButton>
            </div>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
