import create, { GetState, SetState, State } from 'zustand'
import { AxiosResponse } from 'axios'
import Bugsnag, { NotifiableError } from '@bugsnag/js'
import sortBy from 'lodash/sortBy'

import {
  api as holdsApi,
  AddHold,
  GetHoldsStatus,
  HoldAction,
} from 'api/holdsApi'
import { HoldStatus } from 'common'

interface HoldsStatus {
  currentHoldsMessage: string
  remainingHoldsMessage: string
}

interface UseHoldsState extends State {
  holds: Hold[]
  holdsStatus: HoldsStatus
  isLoadingHolds: boolean
  addHold: (addHoldData: AddHold) => Promise<void>
  declineHold: (declineHoldData: HoldAction) => Promise<void>
  deleteHold: (deleteHoldData: HoldAction) => Promise<void>
  fetchHolds: (hooplaUserId: string) => Promise<Hold[]>
  getHoldsStatus: (holdStatusData: GetHoldsStatus) => Promise<void>
  snoozeHold: (snoozeHoldData: HoldAction) => Promise<void>
  suspendHold: (suspendHoldData: HoldAction) => Promise<void>
  teardownHolds: () => void
  unsuspendHold: (unsuspendHoldData: HoldAction) => Promise<Hold>
}

const defaultState: {
  holds: Hold[]
  holdsStatus: HoldsStatus
  isLoadingHolds: boolean
} = {
  holds: [],
  holdsStatus: { currentHoldsMessage: '', remainingHoldsMessage: '' },
  isLoadingHolds: false,
}

const updateHolds = (holds: Hold[], updatedHold: Hold) => {
  const updatedHolds = holds.map((hold) => {
    if (hold.id === updatedHold.id) {
      return { ...hold, ...updatedHold }
    }
    return hold
  })
  return updatedHolds
}

const useState = create<UseHoldsState>(
  (set: SetState<UseHoldsState>, get: GetState<UseHoldsState>) => ({
    holds: defaultState.holds,
    holdsStatus: defaultState.holdsStatus,
    isLoadingHolds: defaultState.isLoadingHolds,

    addHold: async (addHoldData: AddHold) => {
      Bugsnag.leaveBreadcrumb('Add a hold', addHoldData, 'process')

      try {
        const { data: newHold }: AxiosResponse<Hold> = await holdsApi.addHold(
          addHoldData,
        )
        const cached: Hold[] = get().holds
        cached.unshift(newHold)
        set({ holds: cached })
      } catch (error: any) {
        Bugsnag.notify(error as NotifiableError)
        throw error
      }
    },

    declineHold: async (declineHoldData: HoldAction) => {
      Bugsnag.leaveBreadcrumb('Declined a hold', declineHoldData, 'process')

      try {
        await holdsApi.declineHold(declineHoldData)
        const cached: Hold[] = get().holds
        const updated = cached.map((hold) => {
          if (hold.id === declineHoldData.holdId) {
            return { ...hold, status: 'DECLINED' }
          } else {
            return hold
          }
        })

        set({ holds: updated })
      } catch (error: any) {
        Bugsnag.notify(error as NotifiableError)
        throw error
      }
    },

    deleteHold: async (deleteHoldData: HoldAction) => {
      Bugsnag.leaveBreadcrumb('Deleted a hold', deleteHoldData, 'process')

      try {
        await holdsApi.deleteHold(deleteHoldData)
        const cached: Hold[] = get().holds
        const updated: Hold[] = cached.filter(
          (hold) => hold.id !== deleteHoldData.holdId,
        )
        set({ holds: updated })
      } catch (error: any) {
        Bugsnag.notify(error as NotifiableError)
        throw error
      }
    },

    fetchHolds: async (hooplaUserId: string) => {
      set({ isLoadingHolds: true })

      try {
        const { data: holds }: AxiosResponse<Hold[]> =
          await holdsApi.fetchHolds(hooplaUserId)
        const sortedHolds = sortBy(holds, 'inserted').reverse()
        set({ holds: sortedHolds, isLoadingHolds: false })

        return sortedHolds
      } catch (error: any) {
        Bugsnag.notify(error as NotifiableError)
        set({ isLoadingHolds: false })
        throw error
      }
    },

    getHoldsStatus: async (holdStatusData: GetHoldsStatus) => {
      try {
        const { data }: AxiosResponse<HoldsStatus> =
          await holdsApi.getHoldsStatus(holdStatusData)

        set({ holdsStatus: data })
      } catch (error: any) {
        Bugsnag.notify(error as NotifiableError)
        throw error
      }
    },

    snoozeHold: async (snoozeHoldData: HoldAction) => {
      Bugsnag.leaveBreadcrumb('Snoozed a hold', snoozeHoldData, 'process')

      try {
        const { data: snoozedHold }: AxiosResponse<Hold> =
          await holdsApi.snoozeHold(snoozeHoldData)
        const cached: Hold[] = get().holds
        const updatedHolds = updateHolds(cached, snoozedHold)

        set({ holds: updatedHolds })
      } catch (error: any) {
        Bugsnag.notify(error as NotifiableError)
        throw error
      }
    },

    suspendHold: async (suspendHoldData: HoldAction) => {
      Bugsnag.leaveBreadcrumb('Suspended a hold', suspendHoldData, 'process')

      try {
        const { data: suspendedHold }: AxiosResponse<Hold> =
          await holdsApi.suspendHold(suspendHoldData)
        const cached: Hold[] = get().holds
        const updatedHolds = updateHolds(cached, suspendedHold)

        set({ holds: updatedHolds })
      } catch (error: any) {
        Bugsnag.notify(error as NotifiableError)
        throw error
      }
    },

    teardownHolds: (): void => {
      set(defaultState)
    },

    unsuspendHold: async (unsuspendHoldData: HoldAction) => {
      Bugsnag.leaveBreadcrumb(
        'Unsuspended a hold',
        unsuspendHoldData,
        'process',
      )

      try {
        const { data: unsuspendedHold }: AxiosResponse<Hold> =
          await holdsApi.unsuspendHold(unsuspendHoldData)
        const cached: Hold[] = get().holds
        const updatedHolds = updateHolds(cached, unsuspendedHold)

        set({ holds: updatedHolds })

        return unsuspendedHold
      } catch (error: any) {
        Bugsnag.notify(error as NotifiableError)
        throw error
      }
    },
  }),
)

interface UseHoldsResponse extends UseHoldsState {
  formattedHolds: {
    readyToBorrow: Hold[]
    waiting: Hold[]
    suspended: Hold[]
    snoozed: Hold[]
  }
}

export function useHolds(): UseHoldsResponse {
  const holds = useState((state) => state.holds)
  const holdsStatus = useState((state) => state.holdsStatus)
  const isLoadingHolds = useState((state) => state.isLoadingHolds)

  const addHold = useState((state) => state.addHold)
  const declineHold = useState((state) => state.declineHold)
  const deleteHold = useState((state) => state.deleteHold)
  const fetchHolds = useState((state) => state.fetchHolds)
  const getHoldsStatus = useState((state) => state.getHoldsStatus)
  const snoozeHold = useState((state) => state.snoozeHold)
  const suspendHold = useState((state) => state.suspendHold)
  const teardownHolds = useState((state) => state.teardownHolds)
  const unsuspendHold = useState((state) => state.unsuspendHold)

  const formattedHolds = {
    readyToBorrow: holds.filter((hold) => hold.status === HoldStatus.Reserved),
    waiting: holds.filter((hold) => hold.status === HoldStatus.Waiting),
    suspended: holds.filter((hold) => hold.status === HoldStatus.Suspended),
    snoozed: holds.filter((hold) => hold.status === HoldStatus.Snoozed),
  }

  return {
    formattedHolds,
    holds,
    holdsStatus,
    isLoadingHolds,

    addHold,
    declineHold,
    deleteHold,
    fetchHolds,
    getHoldsStatus,
    snoozeHold,
    suspendHold,
    teardownHolds,
    unsuspendHold,
  }
}
