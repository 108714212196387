import { holdsClient } from 'axiosClients/holdsAPI'

export interface AddHold {
  hooplaUserId: string
  patronId: string
  contentId: string
}

export interface GetHoldsStatus {
  hooplaUserId: string
  patronId: string
}

export interface HoldAction {
  hooplaUserId: string
  patronId: string
  holdId: string
}

export const api = {
  addHold: ({ hooplaUserId, patronId, contentId }: AddHold) => {
    return holdsClient.post(
      `/users/${hooplaUserId}/patrons/${patronId}/holds/${contentId}`,
    )
  },
  declineHold: ({ hooplaUserId, patronId, holdId }: HoldAction) => {
    return holdsClient.put(
      `/users/${hooplaUserId}/patrons/${patronId}/holds/${holdId}/decline`,
      {},
    )
  },
  deleteHold: ({ hooplaUserId, patronId, holdId }: HoldAction) => {
    return holdsClient.delete(
      `/users/${hooplaUserId}/patrons/${patronId}/holds/${holdId}`,
    )
  },
  fetchHolds: (hooplaUserId: string) => {
    return holdsClient.get(`/users/${hooplaUserId}/holds`)
  },
  getHoldsStatus: ({ hooplaUserId, patronId }: GetHoldsStatus) => {
    return holdsClient.get(
      `/users/${hooplaUserId}/patrons/${patronId}/holds/status`,
    )
  },
  snoozeHold: ({ hooplaUserId, patronId, holdId }: HoldAction) => {
    return holdsClient.put(
      `/users/${hooplaUserId}/patrons/${patronId}/holds/${holdId}/snooze`,
      {},
    )
  },
  suspendHold: ({ hooplaUserId, patronId, holdId }: HoldAction) => {
    return holdsClient.put(
      `/users/${hooplaUserId}/patrons/${patronId}/holds/${holdId}/suspend`,
      {},
    )
  },
  unsuspendHold: ({ hooplaUserId, patronId, holdId }: HoldAction) => {
    return holdsClient.put(
      `/users/${hooplaUserId}/patrons/${patronId}/holds/${holdId}/unsuspend`,
      {},
    )
  },
}
