import { Helmet } from 'components/common/Helmet'
import usePage from 'components/hooks/usePage'
import { StringParam, useQueryParams } from 'components/hooks/useQueryParams'

const CanonicalLink = () => {
  const [page] = usePage()
  const { pathname, origin } = window.location

  const [{ kindId }] = useQueryParams({ kindId: StringParam })

  let canonicalHref = `${origin}${pathname}`
  if (kindId) canonicalHref += `?kindId=${kindId}`
  if (page > 1) canonicalHref += `?page=${page}`

  return (
    <Helmet
      link={[
        {
          rel: 'canonical',
          href: canonicalHref,
        },
      ]}
    />
  )
}

export default CanonicalLink
