import { useEffect } from 'react'

import { useRafState } from './useRafState'

const mql: MediaQueryList = window.matchMedia(
  'screen and (prefers-reduced-motion: reduce)',
)

export function useReducedMotion(): boolean {
  const [prefersReducedMotion, setPrefersReducedMotion] = useRafState(
    mql.matches,
  )

  const onChange = (e: MediaQueryListEvent) => {
    setPrefersReducedMotion(e.matches)
  }

  useEffect(() => {
    if (mql.addEventListener) {
      mql.addEventListener('change', onChange)
    }

    return () => {
      if (mql.removeEventListener) {
        mql.removeEventListener('change', onChange)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return prefersReducedMotion
}
