import { apiGatewayClient } from 'axiosClients/apiGatewayAPI'

export const api = {
  getTerms: (token) =>
    apiGatewayClient.get(
      '/terms/PATRON',
      token && { headers: { Authorization: `Bearer ${token}` } },
    ),
  postTerms: (token, docId) =>
    apiGatewayClient.post(
      '/terms',
      { docId },
      { headers: { Authorization: `Bearer ${token}` } },
    ),
}
