import { FocusEventHandler, forwardRef } from 'react'
import classNames from 'classnames'

import { FormGroup, FormLabel } from 'components/ui'

interface CheckboxProps {
  ariaLabel?: string
  className?: string
  error?: string
  id: string
  label?: JSX.Element
  onChange: FocusEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  name: string
  disabled?: boolean
}

const Checkbox = forwardRef(
  (
    {
      ariaLabel,
      className,
      error,
      id,
      label,
      name,
      onChange,
      onBlur,
      disabled = false,
    }: CheckboxProps,
    ref,
  ): JSX.Element => {
    const styling = classNames(
      'form-check-input float-left mt-1 mr-2 h-4 w-4 cursor-pointer appearance-none rounded-sm border border-gray-300 bg-white bg-contain bg-center bg-no-repeat transition duration-200 checked:border-blue-600 checked:bg-blue-600 focus:outline-none disabled:checked:border-gray-600 disabled:checked:bg-gray-600',
      { 'border-red-600': error },
    )

    return (
      <FormGroup className="flex text-base">
        <input
          aria-label={ariaLabel}
          className={classNames(styling, className)}
          id={id}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref as any}
          type={'checkbox'}
          disabled={disabled}
        />

        <>
          {label && (
            <FormLabel
              className={classNames('!mb-0', { 'text-red-600': error })}
              disabled={disabled}
              hidden={!label}
              htmlFor={id}>
              {label}
            </FormLabel>
          )}
        </>
      </FormGroup>
    )
  },
)

export default Checkbox
