import { ChangeEvent } from 'react'

interface SelectProps {
  children: JSX.Element[] | JSX.Element
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void
  id: string
  value: string | number | boolean
  width?: string
}

const Select = ({ children, id, onChange, value, width = '' }: SelectProps) => {
  return (
    <div
      className="relative flex cursor-pointer items-center rounded-md border border-solid border-gray-300"
      style={{ width }}>
      <select
        className="z-10 m-0 h-auto w-full appearance-none border-none bg-transparent py-1.5 pl-2 pr-8"
        id={id}
        onChange={onChange}
        value={value?.toString()}>
        {children}
      </select>
    </div>
  )
}

export default Select
