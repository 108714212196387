import { isLoggedIn } from 'util/user'
import { useLibrary } from 'state/useLibrary'

const LOCALE_NOT_AZ: string[] = ['US', 'CA', '419']
const locale: string = navigator.languages
  ? navigator.languages[0]
  : navigator.language
const country: string =
  locale && locale.split('-').length > 1 ? locale.split('-')[1] : 'US'

function useIsAUNZ(): boolean {
  const library = useLibrary((state) => state.library)
  const isAuthenticated: boolean = isLoggedIn()

  if (isAuthenticated && library) {
    return library?.country === 'AU' || library?.country === 'NZ'
  } else {
    if (
      window.location.href.indexOf('nz') > -1 ||
      window.location.href.indexOf('au') > -1
    ) {
      return true
    } else {
      return LOCALE_NOT_AZ.indexOf(country) === -1
    }
  }
}

export default useIsAUNZ
