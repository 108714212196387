/******** GEOLOCATION CAPTURING FOR LATITUDE/LONGITUDE COORDINATES *********/
import Bugsnag from '@bugsnag/js'

export function getCoordinates() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position.coords)
        },
        (e) => {
          Bugsnag.leaveBreadcrumb('Location services rejected by client', {
            reason: e.message,
          })
          return reject(e)
        },
        {
          enableHighAccuracy: false,
          maximumAge: 86400000, // 1 day
          timeout: 10000, // 10 seconds
        },
      )
    }
  })
}

//
//
// Coordinates are also stored in local storage so they can be used
// either inside or outside of react / hooks
//
//

export function getCurrentCoordinates() {
  const coordinates = localStorage.getItem('coordinates')
  return coordinates ? JSON.parse(coordinates) : null
}

export function setCurrentCoordinates(coordinates) {
  return localStorage.setItem('coordinates', JSON.stringify(coordinates))
}

export function clearCurrentCoordinates() {
  return localStorage.removeItem('coordinates')
}
