import { defaultIconProps } from 'components/common/icons'

export const ChevronDown = ({
  size = defaultIconProps.size,
  className = defaultIconProps.className,
}: DefaultIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    className={className}>
    <polyline points="6 9 12 15 18 9"></polyline>
  </svg>
)
