import { useSearchParams as reactRouterParams } from 'react-router-dom'

export type ParameterValue = number | boolean | string | undefined | null
export type ParamsObj = { [key: string]: ParameterValue }
export type ParamConfigMap = { [key: string]: FormatType }
export type SetParams = (valueMap: ParamsObj, option?: string) => void

export enum FormatType {
  StringParam = 'STRING',
  NumberParam = 'NUMBER',
  BooleanParam = 'BOOLEAN',
}

export function useQueryParams(
  paramConfigMap: ParamConfigMap,
): [ParamsObj, SetParams] {
  const [searchParams, setSearchParams] = reactRouterParams()
  const params: ParamsObj = Object.keys(paramConfigMap).reduce(
    (acc: ParamsObj, cur: string) => {
      acc[cur] = formatReturnValue(
        searchParams.get(cur),
        paramConfigMap[cur] as FormatType,
      )
      return acc
    },
    {},
  )

  function setParams(valueMap: ParamsObj, option = 'pushIn'): void {
    Object.keys(valueMap).forEach((cur: string) => {
      if (valueMap[cur]) {
        searchParams.set(
          cur,
          formatSetValue(valueMap[cur], paramConfigMap[cur] as FormatType),
        )
      } else {
        searchParams.delete(cur)
      }
    })

    setSearchParams(searchParams, { replace: option !== 'pushIn' })
  }

  return [params, setParams]
}

export function useQueryParam<paramType>(
  param: string,
  type: FormatType,
  defaultValue?: paramType,
) {
  const [searchParams, setSearchParams] = reactRouterParams()
  function setParam(v: ParameterValue, option = 'pushIn'): void {
    if (v) {
      searchParams.set(param, formatSetValue(v, type))
    } else {
      searchParams.delete(param)
    }
    setSearchParams(searchParams, { replace: option !== 'pushIn' })
  }

  const formattedValue = formatReturnValue(
    searchParams.get(param),
    type,
  ) as any as paramType
  return [
    formattedValue ?? defaultValue,
    setParam as any as (val: paramType) => void,
  ] as const
}

function formatReturnValue(value: ParameterValue, type: FormatType) {
  if (value) {
    switch (type) {
      case NumberParam:
        if (typeof value === 'string') return parseInt(value, 10)
        break
      case BooleanParam:
        return value === '1' || value === 'true'
      case StringParam:
      default:
        return String(value)
    }
  }
}

function formatSetValue(value: ParameterValue, type: FormatType): string {
  switch (type) {
    case BooleanParam:
      return value ? '1' : '0'
    case NumberParam:
    case StringParam:
    default:
      return String(value)
  }
}

export const StringParam = 'STRING' as FormatType
export const NumberParam = 'NUMBER' as FormatType
export const BooleanParam = 'BOOLEAN' as FormatType
