import { LicenseType } from 'common'
import { BaseEvent } from 'components/hooks/analytics/eventTypes'
import useEvents from 'components/hooks/analytics/useEvents'
import { useCallback } from 'react'

export type HootieEvent = BaseEvent & {
  label: string
  eventSource?: string
  value?: HootieEventValue
}

type HootieEventValue =
  | HootieCardData
  | HootieFeedback
  | HootieGreeting
  | HootieResponse
  | UserInput

type HootieCardData = {
  caseId: string
  id: string
  licenseType: LicenseType
}

type HootieFeedback = {
  caseId?: string
  feedback?: boolean
  source?: string
}

type HootieGreeting = {
  bottomMessage?: string
  caseId?: string
  topMessage?: string
}

type HootieResponse = {
  bottomMessage?: string
  caseId?: string
  eventSource?: string
  titleList: Record<string, number | string | undefined>[]
  topMessage?: string
}

type UserInput = {
  q: string
  caseId?: string
}
// 2x type gross, but felt like the cleanest way without using `q` as a variable
type UserPrompt = {
  prompt: UserInput['q']
  caseId: UserInput['caseId']
}

type hootieEventActions = {
  sendHootieBackClickEvent: () => void
  sendHootieCardClickEvent: (value: HootieCardData, eventSource: string) => void
  sendHootieFeedbackEvent: (value: HootieFeedback, eventSource: string) => void
  sendHootieGreetingEvent: (value: HootieGreeting) => void
  sendHootiePageLoadEvent: () => void
  sendHootieResponseEvent: (value: HootieResponse, eventSource: string) => void
  sendHootieTimeoutEvent: () => void
  sendHootieUnknownErrorEvent: () => void
  sendHootieUserInputEvent: (value: UserPrompt) => void
}

const hootieView = { name: 'HOOTIE' }

export const useHootieEvents = (): hootieEventActions => {
  const { sendEvent } = useEvents()

  const sendHootieEvent = useCallback(
    ({
      category = 'HOOTIE',
      eventSource,
      label,
      interactionType = 'EVENT',
      value,
      view = hootieView,
    }: HootieEvent) =>
      sendEvent({
        category,
        eventSource,
        interactionType,
        label,
        value,
        view,
      }),

    [sendEvent],
  )

  const sendHootieBackClickEvent = useCallback(() => {
    sendHootieEvent({
      label: 'back_click',
    })
  }, [sendHootieEvent])

  const sendHootieCardClickEvent = useCallback(
    (value: HootieCardData, eventSource: string) => {
      sendHootieEvent({
        category: 'CLICK',
        eventSource,
        label: 'hootie_title_clicked',
        value: {
          ...value,
          type: 'title',
        },
      })
    },
    [sendHootieEvent],
  )

  const sendHootieFeedbackEvent = useCallback(
    (value: HootieFeedback, eventSource: string) =>
      sendHootieEvent({
        eventSource,
        category: 'CLICK',
        label: 'hootie_feedback',
        value,
      }),
    [sendHootieEvent],
  )

  const sendHootieGreetingEvent = useCallback(
    (value: HootieGreeting) =>
      sendHootieEvent({
        interactionType: 'APP_EVENT',
        label: 'greeting',
        value,
      }),
    [sendHootieEvent],
  )

  const sendHootiePageLoadEvent = useCallback(
    () =>
      sendEvent({
        category: 'HOOTIE',
        interactionType: 'PAGE_LOAD',
        view: hootieView,
      }),
    [sendEvent],
  )

  const sendHootieResponseEvent = useCallback(
    (value: HootieResponse, eventSource: string) =>
      sendHootieEvent({
        eventSource,
        interactionType: 'APP_EVENT',
        label: 'response',
        value,
      }),
    [sendHootieEvent],
  )

  const sendHootieTimeoutEvent = useCallback(
    () =>
      sendHootieEvent({
        interactionType: 'APP_EVENT',
        label: 'timeout',
      }),
    [sendHootieEvent],
  )

  const sendHootieUnknownErrorEvent = useCallback(
    () =>
      sendHootieEvent({
        interactionType: 'APP_EVENT',
        label: 'unknown_error',
      }),
    [sendHootieEvent],
  )

  const sendHootieUserInputEvent = useCallback(
    ({ prompt, caseId }: UserPrompt) =>
      sendHootieEvent({
        label: 'user_q',
        value: {
          caseId,
          q: prompt,
        },
      }),
    [sendHootieEvent],
  )

  return {
    sendHootieBackClickEvent,
    sendHootieCardClickEvent,
    sendHootieFeedbackEvent,
    sendHootieGreetingEvent,
    sendHootiePageLoadEvent,
    sendHootieResponseEvent,
    sendHootieTimeoutEvent,
    sendHootieUnknownErrorEvent,
    sendHootieUserInputEvent,
  }
}
