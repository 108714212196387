// REACT IMPORTS
import { HTMLInputTypeAttribute, FocusEventHandler, forwardRef } from 'react'

// ABSOLUTE IMPORTS
import classNames from 'classnames'
import { VisibilityOff, VisibilityOn } from 'components/common/icons'
import { FormError, FormGroup, FormLabel } from 'components/ui'

interface TextFieldProps {
  ariaLabel?: string
  autoComplete?: string
  className?: string
  disabled?: boolean
  error?: string
  id: string
  inputType?: HTMLInputTypeAttribute
  label?: string
  maxLength?: number
  name: string
  onChange?: FocusEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  placeHolder?: string

  setShowPassword?: (show: boolean) => void
  showPassword?: boolean
}

const TextField = forwardRef(
  (
    {
      ariaLabel,
      autoComplete,
      className,
      disabled,
      error,
      id,
      inputType,
      label,
      maxLength,
      placeHolder,
      onChange = () => {},
      onBlur = () => {},
      name,
      setShowPassword,
      showPassword = false,
    }: TextFieldProps,
    ref,
  ): JSX.Element => {
    const styling = classNames(
      'appearance-none shadow-none box-border m-0 !w-full px-3 py-2 bg-white',
      'border border-secondary-highlight rounded',
      'disabled:text-secondary-highlight',
      'disabled:border-secondary-highlight disabled:border',
      'disabled:placeholder:text-secondary-highlight',
      { '!border-warning-base': !!error },
      {
        'outline-0 border-2 border-border-hoopla-blue-light':
          !!error && !disabled,
      },
    )

    return (
      <FormGroup>
        <>
          {label && (
            <FormLabel disabled={disabled} hidden={!label} htmlFor={id}>
              {label}
            </FormLabel>
          )}
        </>
        <div className="relative">
          <input
            aria-label={ariaLabel ?? label}
            className={(classNames(className), styling)}
            disabled={disabled ?? false}
            id={id}
            maxLength={maxLength ?? 250}
            placeholder={placeHolder ?? ''}
            ref={ref as any}
            type={inputType ?? 'text'}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            autoComplete={autoComplete}
          />

          {setShowPassword && (
            <div className="absolute top-2.5 right-3">
              <button
                className="cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
                type="button">
                {showPassword ? (
                  <VisibilityOn className="fill-gray-700" />
                ) : (
                  <VisibilityOff className="fill-gray-700" />
                )}
              </button>
            </div>
          )}
        </div>
        <>{error && <FormError>{error}</FormError>}</>
      </FormGroup>
    )
  },
)

export default TextField
