import { Menu, Transition } from '@headlessui/react'

interface DropdownMenuButtonProps {
  children: JSX.Element
  label: JSX.Element
}

const DropdownMenuButton = ({ children, label }: DropdownMenuButtonProps) => {
  return (
    <Menu as="div" className="relative bottom-2 z-40">
      <Menu.Button className="-mx-1.5 flex h-full h-10 items-center rounded px-2 text-sm font-semibold uppercase text-white filter hover:bg-hoopla-blue-dark hover:text-secondary-pale focus:bg-hoopla-blue-dark focus:text-secondary-pale active:bg-hoopla-blue-dark active:text-secondary-pale">
        <span className="flex items-center">
          <>
            {label}
            <svg
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              className="ml-1 h-3 w-3"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </>
        </span>
      </Menu.Button>
      <Transition
        enter="transition duration-300 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-200 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0">
        <Menu.Items className="absolute left-0 top-1 flex min-w-40 flex-col divide-y divide-gray-200 rounded bg-white p-2 shadow-lg">
          {children}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default DropdownMenuButton
