const config = {
  // defaults - dev process.env
  analyticsApi: process.env.REACT_APP_ANALYTICS_API,
  apiGatewayAPI: process.env.REACT_APP_API_GATEWAY_API,
  bisacsEnabled: process.env.REACT_APP_BISACS_ENABLED === 'true',
  branchApiKey: process.env.REACT_APP_BRANCH_API_KEY,
  brazeApiKey: process.env.REACT_APP_BRAZE_API_KEY,
  captionsSettingsVersion: process.env.REACT_APP_CAPTION_SETTINGS_VERSION,
  chromecastAppId: process.env.REACT_APP_CHROMECAST_APP_ID,
  comicSettingsVersion: process.env.REACT_APP_COMIC_SETTINGS_VERSION,
  comicTutorialUrl: process.env.REACT_APP_COMICS_TUTORIAL_URL,
  dashUrl: process.env.REACT_APP_DASH_URL,
  ebookContentAPI: process.env.REACT_APP_EBOOK_CONTENT_API,
  ebookContentKey: process.env.REACT_APP_EBOOK_CONTENT_KEY,
  ebookSettingsVersion: process.env.REACT_APP_EBOOK_SETTINGS_VERSION,
  fairplayUrl: process.env.REACT_APP_FAIRPLAY_URL,
  googleAnalytics: process.env.REACT_APP_GOOGLE_ANALYTICS,
  gtmTrackingId: process.env.REACT_APP_GTM_KEY,
  graphqlTitlesPerPage: parseInt(
    process.env.REACT_APP_GRAPHQL_TITLES_PER_PAGE as string,
    10,
  ),
  hooplaGatewayApi: process.env.REACT_APP_HOOPLA_GATEWAY_API,
  kidsModeTutorialUrl: process.env.REACT_APP_KIDS_MODE_TUTORIAL_URL,
  librarySplashUrl: process.env.REACT_APP_LIBRARY_SPLASH_URL,
  libraryUrl: process.env.REACT_APP_LIBRARY_URL,
  mediaWs: process.env.REACT_APP_MEDIA_WS,
  owlbotToken: process.env.REACT_APP_OWLBOT_TOKEN,
  patronApiGateway: process.env.REACT_APP_PATRON_API_GATEWAY,
  parserFactoryEnabled: process.env.REACT_APP_PARSER_FACTORY_ENABLED === 'true',
  prestoPlayLicenseKey: process.env.REACT_APP_PRESTO_PLAY_LICENSE_KEY,
  searchQueryMaxLength: process.env.REACT_APP_SEARCH_MAX_LENGTH,
  titleArtUrl: process.env.REACT_APP_TITLE_ART_URL,
  titlesPerPage: process.env.REACT_APP_TITLES_PER_PAGE,
  vendorUrl: process.env.REACT_APP_VENDOR_URL,
}

export default config
