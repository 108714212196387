import { AUDIENCE_CHILDREN } from 'constants/audience'
import Cookies from 'universal-cookie'
import addYears from 'date-fns/addYears'
import create, { GetState, SetState, State } from 'zustand'

const cookies = new Cookies()

export const HOOPLA_KIDS_MODE_KEY = 'hoopla:kidsMode'
export const KIDS_MODE_PIN_KEY = 'hoopla:kidsModePIN'

type KidsModePin = {
  pin?: number
  isKidsMode: boolean
}

interface KidsModeState extends State {
  audience: string | undefined
  isKidsMode: boolean
  pin?: number
  setKidsMode: (isKidsMode: boolean) => void
  teardown: () => void
}

export const useKidsMode = create(
  (set: SetState<KidsModeState>, _get: GetState<KidsModeState>) => ({
    isKidsMode: isKidsMode(),
    pin: getKidsModePin(),
    audience: isKidsMode() ? AUDIENCE_CHILDREN : undefined,
    setKidsMode: function (kidsModePin: KidsModePin) {
      const { isKidsMode, pin } = kidsModePin
      set({
        isKidsMode,
        audience: isKidsMode ? AUDIENCE_CHILDREN : undefined,
        pin,
      })
      setKidsMode(kidsModePin)
    },
    teardown: function () {
      set({ isKidsMode: false, pin: undefined, audience: undefined })
      clearKidsModePin()
    },
  }),
)

export function isKidsMode(): boolean {
  if (legacyCheck()) {
    setKidsMode({ isKidsMode: true })
    clearLegacyKidsMode()
    return true
  }

  const kidsMode: KidsModePin = cookies.get(KIDS_MODE_PIN_KEY)
  return !!kidsMode?.isKidsMode
}

function getKidsModePin(): number | undefined {
  const kidsMode: KidsModePin = cookies.get(KIDS_MODE_PIN_KEY)
  return kidsMode?.pin
}

export function clearLegacyKidsMode(): void {
  cookies.remove(HOOPLA_KIDS_MODE_KEY, { path: '/' })
}

export function clearKidsModePin(): void {
  cookies.remove(KIDS_MODE_PIN_KEY, { path: '/' })
}

function legacyCheck() {
  const isKidsMode: string = cookies.get(HOOPLA_KIDS_MODE_KEY)
  return isKidsMode === 'true'
}

function setKidsMode(kidsModePin: KidsModePin): void {
  cookies.set(KIDS_MODE_PIN_KEY, JSON.stringify(kidsModePin), {
    secure: true,
    sameSite: true,
    expires: addYears(new Date(), 100),
    path: '/',
  })
}
