import { getDeviceId } from 'util/user'
import { useCallback, useEffect, useMemo } from 'react'
import axios from 'axios'
import Bugsnag from '@bugsnag/browser'
import config from 'config'
import { useUser } from 'state/useUser'
import UAParser from 'ua-parser-js'
import create, { SetState } from 'zustand'
import {
  AppStartEvent,
  CarouselEvent,
  ClickEvent,
  ConversionEvent,
  Event,
  GridLoadedEvent,
  PageLoadDetails,
  RegistrationEvent,
  SearchEvent,
  TitleDetailsEvent,
  View,
} from './eventTypes'
import EventLabel from './eventLabel'

type pageLoadedState = {
  currentPageLoaded?: string
  setCurrentPageLoaded: (currentPageLoaded: string) => void
}

type EventActions = {
  sendEvent: (data: Event) => void
  sendClickEvent: (
    data: Omit<ClickEvent, 'category' | 'interactionType'>,
  ) => void
  sendRegistrationEvent: (data: Omit<RegistrationEvent, 'category'>) => void
  sendSearchEvent: (
    data: Omit<SearchEvent, 'category' | 'interactionType'>,
  ) => void
  sendConversionEvent: (data: Omit<ConversionEvent, 'category'>) => void
  sendTitleDetailsEvent: (
    data: Omit<TitleDetailsEvent, 'category' | 'interactionType' | 'label'>,
  ) => void
  sendAppStartEvent: (
    data: Omit<AppStartEvent, 'category' | 'interactionType'>,
  ) => void
  sendCarouselEvent: (
    data: Omit<CarouselEvent, 'category' | 'interactionType'>,
  ) => void
  setPageLoaded: (pageLoaded: View, pageLoadDetails?: PageLoadDetails) => void
  sendGridLoadedEvent: (
    data: Omit<GridLoadedEvent, 'category' | 'interactionType' | 'label'>,
  ) => void
}

const parser = new UAParser()

const pageLoaded = create<pageLoadedState>(
  (set: SetState<pageLoadedState>) => ({
    currentPageLoaded: undefined,
    setCurrentPageLoaded: (currentPageLoaded) => set({ currentPageLoaded }),
  }),
)

const useEvents = (): EventActions => {
  const user = useUser((state) => state.user)
  const patron = user?.patrons?.[0]
  const libraryId = patron?.libraryId?.toString()
  const patronId = patron?.id?.toString()
  const {
    currentPageLoaded: currentPageLoadedJSON,
    setCurrentPageLoaded: setCurrentPageLoadedJSON,
  } = pageLoaded()

  // sendEvent is only exported for hootieEvents, do not use for other events
  // please add/update an event action + EventType
  const sendEvent = useCallback(
    async (data: Event) => {
      const deviceId = getDeviceId()

      const eventData = {
        app: 'WWW',
        appVersion: process.env.REACT_APP_VERSION,
        deviceId,
        deviceModel: parser.getBrowser().name,
        deviceVersion: parser.getBrowser().version,
        libraryId: libraryId ?? undefined,
        os: parser.getOS().name,
        osVersion: parser.getOS().version,
        patronId: patronId ?? undefined,
        timestamp: new Date().getTime(),
        url: window.location.href,
        ...data,
        view: data.view ? JSON.stringify(data.view) : currentPageLoadedJSON,
        value: data.value ? JSON.stringify(data.value) : undefined,
      }

      try {
        await analyticsClient.post('/patron/event', eventData)
      } catch (error) {
        Bugsnag.leaveBreadcrumb('Failed to send event', { eventData, error })
      }
    },
    [currentPageLoadedJSON, libraryId, patronId],
  )

  const sendClickEvent: EventActions['sendClickEvent'] = useCallback(
    (data) => {
      sendEvent({
        category: 'CLICK',
        interactionType: 'EVENT',
        ...data,
      })
    },
    [sendEvent],
  )

  const sendRegistrationEvent: EventActions['sendRegistrationEvent'] =
    useCallback(
      (data) => {
        sendEvent({
          ...data,
          category: 'REGISTRATION',
          interactionType: data.interactionType ?? 'EVENT',
        })
      },
      [sendEvent],
    )

  const sendSearchEvent: EventActions['sendSearchEvent'] = useCallback(
    (data) => {
      sendEvent({
        category: 'SEARCH',
        interactionType: 'EVENT',
        ...data,
      })
    },
    [sendEvent],
  )

  const sendConversionEvent: EventActions['sendConversionEvent'] = useCallback(
    (data) => {
      sendEvent({
        category: 'CONVERSION',
        ...data,
      })
    },
    [sendEvent],
  )

  const sendCarouselEvent: EventActions['sendCarouselEvent'] = useCallback(
    (data) => {
      if (
        data.label === 'carousel_loaded' &&
        // @ts-ignore ts wants me to prove I have the data before i check if the data is there
        !data.value?.length
      ) {
        return
      }

      if (data.ordinal === undefined) {
        Bugsnag.leaveBreadcrumb(`no ordinal provided on ${data.label}`, {
          value: data.value,
          carouselName: data.sectionHeader,
        })
      }

      sendEvent({
        category: 'CAROUSEL',
        interactionType:
          data.label === 'carousel_loaded' ? 'APP_EVENT' : 'EVENT',
        ...data,
      })
    },
    [sendEvent],
  )

  const setPageLoaded: EventActions['setPageLoaded'] = useCallback(
    (pageLoaded, pageLoadDetails) => {
      // ensures that the page being loaded and value are not sent in an event twice
      // also ensures that a page, such as search, can fire multiple times if the value is updated
      const pageLoadedJSON = JSON.stringify(pageLoaded)
      if (pageLoadedJSON === currentPageLoadedJSON) {
        return
      }

      setCurrentPageLoadedJSON(pageLoadedJSON)
      sendEvent({
        label: pageLoadDetails?.label as EventLabel,
        category: pageLoadDetails?.category,
        interactionType: 'PAGE_LOAD',
        view: pageLoaded,
      })
    },
    [currentPageLoadedJSON, sendEvent, setCurrentPageLoadedJSON],
  )

  const sendTitleDetailsEvent: EventActions['sendTitleDetailsEvent'] =
    useCallback(
      (data) => {
        sendEvent({
          category: 'TITLE_DETAILS',
          interactionType: 'APP_EVENT',
          label: 'title_detail_loaded',
          ...data,
        })
      },
      [sendEvent],
    )

  const sendAppStartEvent: EventActions['sendAppStartEvent'] = useCallback(
    (data) => {
      sendEvent({
        interactionType: 'APP_START',
        ...data,
      })
    },
    [sendEvent],
  )

  const sendGridLoadedEvent: EventActions['sendGridLoadedEvent'] = useCallback(
    (data) => {
      sendEvent({
        category: 'GRID',
        interactionType: 'APP_EVENT',
        ...data,
      })
    },
    [sendEvent],
  )

  return {
    sendEvent,
    setPageLoaded,
    sendClickEvent,
    sendConversionEvent,
    sendCarouselEvent,
    sendGridLoadedEvent,
    sendSearchEvent,
    sendTitleDetailsEvent,
    sendRegistrationEvent,
    sendAppStartEvent,
  }
}

const analyticsClient = axios.create({
  baseURL: config.analyticsApi,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
})

export const EVENT_ERROR = 'ERROR'

export const useEventsWithPageLoad: EventActions['setPageLoaded'] = (data) => {
  const { setPageLoaded, ...eventActions } = useEvents()
  const memoizedData = useMemo(() => data, [data])
  useEffect(() => {
    if (memoizedData) {
      setPageLoaded(memoizedData)
    }
  }, [memoizedData, setPageLoaded])

  return eventActions
}

export default useEvents
