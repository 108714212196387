import { useCallback } from 'react'

import { useHootieState } from 'components/hootie/useHootieState'
import { client } from 'graphql/client'
import { useCurrentlyPlaying } from 'state/useCurrentlyPlaying'
import { useHolds } from 'state/useHolds'
import { useKidsMode } from 'state/useKidsMode'
import { useKinds } from 'state/useKinds'
import { useLibrary } from 'state/useLibrary'
import { useTitleRequests } from 'state/useTitleRequests'
import { useUser } from 'state/useUser'
import useLaunchDarklyObserver from 'state/useLaunchDarklyObserver'

export function useLogout(): { completeLogout: () => Promise<void> } {
  const teardownSettings = useKidsMode((state) => state.teardown)
  const { clearCurrentlyPlayingTitle } = useCurrentlyPlaying()
  const teardownUser = useUser((state) => state.teardown)
  const getUser = useUser((state) => state.getUser)
  const teardownLibrary = useLibrary((state) => state.teardown)
  const { teardownTitleRequests } = useTitleRequests()
  const { teardownHolds } = useHolds()
  const { teardownHootie } = useHootieState()
  useLaunchDarklyObserver()

  const refetchKinds: Function = useKinds((state) => state.refetch)

  const completeLogout = useCallback(
    async function completeLogout() {
      clearCurrentlyPlayingTitle()
      teardownSettings()
      teardownUser()
      teardownLibrary()
      teardownTitleRequests()
      teardownHolds()
      teardownHootie()

      // reset store, so cached data will be contextual
      // to an anonymous user
      await client.resetStore()

      // refetch kinds because they can be different
      // when unauthenticated vs authenticated
      await getUser()
      await refetchKinds()
    },
    [
      getUser,
      clearCurrentlyPlayingTitle,
      teardownSettings,
      teardownUser,
      teardownLibrary,
      teardownTitleRequests,
      teardownHolds,
      teardownHootie,
      refetchKinds,
    ],
  )

  return {
    completeLogout,
  }
}
