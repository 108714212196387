interface BadgeListItemProps {
  badge: Badge
  className?: string
}

const BadgeListItem = ({
  badge: { href, src, target },
  className,
}: BadgeListItemProps) => (
  <div className={className} key={src}>
    {href && (
      <a href={href} target={target} className="block">
        <img src={src} alt={target.replace(/_/g, ' ')} />
      </a>
    )}

    {!href && <img src={src} alt={target.replace(/_/g, ' ')} />}
  </div>
)

export default BadgeListItem
