import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  default: {
    id: 'chromecast.captions.color.default',
    defaultMessage: 'Default',
  },
  white: { id: 'chromecast.captions.color.white', defaultMessage: 'White' },
  black: { id: 'chromecast.captions.color.black', defaultMessage: 'Black' },
  red: { id: 'chromecast.captions.color.red', defaultMessage: 'Red' },
  green: { id: 'chromecast.captions.color.green', defaultMessage: 'Green' },
  blue: { id: 'chromecast.captions.color.blue', defaultMessage: 'Blue' },
  yellow: { id: 'chromecast.captions.color.yellow', defaultMessage: 'Yellow' },
  magenta: {
    id: 'chromecast.captions.color.magenta',
    defaultMessage: 'Magenta',
  },
  cyan: { id: 'chromecast.captions.color.cyan', defaultMessage: 'Cyan' },
})

export const colorMap = {
  default: '',
  white: '#FFFFFF',
  black: '#000000',
  red: '#FF0000',
  green: '#00FF00',
  blue: '#0000FF',
  yellow: '#FFFF00',
  magenta: '#FF00FF',
  cyan: '#00FFFF',
}

const opacityMap = {
  100: 'FF',
  75: 'BF',
  50: '80',
  25: '40',
}

const scaleMap = {
  xSmall: 0.5,
  small: 0.75,
  medium: 1,
  large: 1.5,
  xLarge: 1.75,
}

export const captionsSettingsDefaults = {
  fontColor: 'default',
  fontOpacity: 100,
  fontSize: 'medium',
  edgeStyle: 'default',
  backgroundColor: 'default',
  backgroundOpacity: 100,
}

export const buildStylesForSettings = (textTrackStyle) => {
  let localCaptionsSettings = localStorage.getItem(
    'hoopla-chromecast-caption-settings',
  )
  if (localCaptionsSettings) {
    localCaptionsSettings = JSON.parse(localCaptionsSettings)
    if (localCaptionsSettings.edgeStyle !== 'default') {
      textTrackStyle.edgeType = localCaptionsSettings.edgeStyle
    }

    if (localCaptionsSettings.fontColor !== 'default') {
      textTrackStyle.foregroundColor =
        colorMap[localCaptionsSettings.fontColor] +
        opacityMap[localCaptionsSettings.fontOpacity]
    } else {
      textTrackStyle.foregroundColor =
        colorMap.white + opacityMap[localCaptionsSettings.fontOpacity]
    }

    if (localCaptionsSettings.backgroundColor !== 'default') {
      textTrackStyle.backgroundColor =
        colorMap[localCaptionsSettings.backgroundColor] +
        opacityMap[localCaptionsSettings.backgroundOpacity]
    } else {
      textTrackStyle.backgroundColor =
        colorMap.black + opacityMap[localCaptionsSettings.backgroundOpacity]
    }

    textTrackStyle.fontScale = scaleMap[localCaptionsSettings.fontSize]
  }
  return textTrackStyle
}
