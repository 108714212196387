import { defaultIconProps } from 'components/common/icons'

export const Remove = ({
  size = defaultIconProps.size,
  className = defaultIconProps.className,
}: DefaultIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width={size}
    height={size}
    fill="currentColor"
    stroke="currentColor"
    className={className}>
    <path d="M10 25.5v-3h28v3Z" />
  </svg>
)
