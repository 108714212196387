import { holdsClient } from 'axiosClients/holdsAPI'

export const api = {
  addTitleRequest: (hooplaUserId, patronId, contentId) => {
    return holdsClient.post(
      `/users/${hooplaUserId}/patrons/${patronId}/title-requests/${contentId}`,
      {},
    )
  },
  deleteTitleRequest: (hooplaUserId, patronId, titleRequestId) => {
    return holdsClient.delete(
      `/users/${hooplaUserId}/patrons/${patronId}/title-requests/${titleRequestId}`,
    )
  },
  fetchTitleRequest: (hooplaUserId, titleRequestId) => {
    return holdsClient.get(
      `/users/${hooplaUserId}/title-requests/${titleRequestId}`,
    )
  },
  fetchTitleRequests: (hooplaUserId) => {
    return holdsClient.get(`/users/${hooplaUserId}/title-requests`)
  },
  getTitleRequestsStatus: (hooplaUserId, patronId) => {
    return holdsClient.get(
      `/users/${hooplaUserId}/patrons/${patronId}/title-requests/status`,
    )
  },
  hideTitleRequest: (hooplaUserId, patronId, holdId) => {
    return holdsClient.put(
      `/users/${hooplaUserId}/patrons/${patronId}/title-requests/hide/${holdId}`,
    )
  },
}
