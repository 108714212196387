import { useCallback, useEffect, useRef, useState } from 'react'

type RafState = any | null

export function useRafState(
  initialState: RafState,
): [any, (value: RafState) => void] {
  const [state, setState] = useState<RafState>(initialState as RafState)

  // keep track of the requestAnimationFrame request ID
  // across renders and successive calls to this hook
  const requestId = useRef(0)

  const setRafState = useCallback((value: RafState) => {
    // cancel active request before making next one
    cancelAnimationFrame(requestId.current)

    // create new request to set state on animation frame
    requestId.current = requestAnimationFrame(() => {
      setState(value)
    })
  }, [])

  // cancel any active request when component unmounts
  useEffect(() => {
    return () => cancelAnimationFrame(requestId.current)
  }, [])

  return [state, setRafState]
}
