import create, { SetState, State } from 'zustand'

interface UseAutopilotState extends State {
  showAutoPilot: boolean
  setShowAutoPilot: (value: boolean) => void
}

export const useAutopilot = create(
  (set: SetState<UseAutopilotState>): UseAutopilotState => ({
    showAutoPilot: true,
    setShowAutoPilot: (value: boolean) => set({ showAutoPilot: value }),
  }),
)
