import { ApolloError } from '@apollo/client'
import create, { SetState, State } from 'zustand'
import { HootieMessage } from 'components/hootie/types'

interface UseHootieState extends State {
  clickedTitleId: string
  error?: ApolloError
  isOpen: boolean
  isFirstOpen: boolean
  fromResume: boolean
  loading: boolean
  responses: HootieMessage[]
  scrollPosition: number
  state: string
  success: boolean
  setClickedTitleId: (clickedTitleId: string) => void
  setError: (error?: ApolloError) => void
  setIsOpen: (isOpen: boolean) => void
  setFirstOpen: (firstOpen: boolean) => void
  setLoading: (loading: boolean) => void
  setResponses: (responses: HootieMessage[]) => void
  setScrollPosition: (scrollPosition: number) => void
  setState: (state: string) => void
  setSuccess: (success: boolean) => void
  teardownHootie: () => void
  setFromResume: (fromResume: boolean) => void
}

const defaultHootieState = {
  clickedTitleId: '',
  error: undefined,
  isOpen: false,
  isFirstOpen: true,
  loading: false,
  fromResume: false,
  responses: [],
  scrollPosition: 0,
  state: '',
  success: false,
}

export const useHootieState = create<UseHootieState>(
  (set: SetState<UseHootieState>) => ({
    ...defaultHootieState,
    setClickedTitleId: (clickedTitleId: string) => set({ clickedTitleId }),
    setError: (error?: ApolloError) => set({ error }),
    setIsOpen: (isOpen: boolean) => set({ isOpen }),
    setLoading: (loading: boolean) => set({ loading }),
    setResponses: (responses: HootieMessage[]) => set({ responses }),
    setScrollPosition: (scrollPosition: number) => set({ scrollPosition }),
    setState: (state: string) => set({ state }),
    setSuccess: (success: boolean) => set({ success }),
    setFirstOpen: (firstOpen: boolean) => set({ isFirstOpen: !firstOpen }),
    teardownHootie: () => set({ ...defaultHootieState }),
    setFromResume: (fromResume: boolean) => set({ fromResume }),
  }),
)
