import { changeBrazeUser } from 'util/braze'
import {
  clearCurrentAuthToken,
  clearCurrentPatronId,
  getCurrentAuthToken,
  setCurrentAuthToken,
  setCurrentPatronId,
} from 'util/user'
import create, { GetState, SetState, State } from 'zustand'
import Bugsnag from '@bugsnag/js'

import { api as userApi } from 'api/userApi'
import { AxiosResponse } from 'axios'

interface UseUserState extends State {
  isLoadingUser: boolean
  user?: User | undefined
  authenticate: (
    username: string,
    password: string,
    remember: boolean,
  ) => Promise<{ status: string; message: string }>
  getUser: (setLoadingUser?: boolean) => Promise<User | undefined>
  setUser: (user: User) => void
  teardown: () => void
}

export const useUser = create<UseUserState>(
  (set: SetState<UseUserState>, get: GetState<UseUserState>): UseUserState => ({
    isLoadingUser: false,
    user: undefined,
    authenticate: async (
      username: string,
      password: string,
      remember: boolean,
    ): Promise<{ status: string; message: string }> => {
      const authenticateResponse: AxiosResponse = await userApi.authenticate(
        username,
        password,
      )
      const { tokenStatus, token, message } = authenticateResponse.data

      if (tokenStatus === 'SUCCESS') {
        setCurrentAuthToken(remember, token)

        Bugsnag.leaveBreadcrumb('Login', { username }, 'user')
      }

      return { status: tokenStatus, message }
    },

    getUser: async (): Promise<User | undefined> => {
      // We don't need to set the loading indicator if we are just refetching the user to update data attributes
      if (!get().user) {
        set({ isLoadingUser: true })
      }

      const token: string = getCurrentAuthToken()

      if (token) {
        try {
          const userResponse: AxiosResponse<User> = await userApi.fetchUser()

          get().setUser(userResponse.data)
        } catch (error) {
          Bugsnag.leaveBreadcrumb('Fetch User Failed', { error })
        }
      }

      set({ isLoadingUser: false })

      return get().user
    },
    setUser: (user: User) => {
      Bugsnag.setUser(user?.id?.toString())

      changeBrazeUser(user?.id?.toString())

      // setup inspectlet user tracking
      window && window.__insp.push(['identify', user?.email])

      setCurrentPatronId(user?.patrons[0]?.id)

      set({ user })
    },
    teardown: (): void => {
      const user: User | undefined = get().user

      Bugsnag.leaveBreadcrumb('Logout', { username: user?.email }, 'user')

      clearCurrentAuthToken()
      clearCurrentPatronId()

      // remove the inspectlet user tracking
      window && window.__insp.push(['identify', 'undefined'])

      set({
        user: undefined,
      })
    },
  }),
)
