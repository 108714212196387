import { NumberParam, useQueryParam } from './useQueryParams'

function usePage(): [number, (page: number) => void] {
  let [page, setPage] = useQueryParam<number | undefined>('page', NumberParam)

  const setNewPage = (newPage: number) =>
    setPage(newPage > 1 ? newPage : undefined)

  return [page ?? 1, setNewPage]
}

export default usePage
