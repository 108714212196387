import axios from 'axios'
import parser from 'ua-parser-js'
import isEmpty from 'lodash/isEmpty'

import appConfig from 'config'
import { isKidsMode } from 'state/useKidsMode'
import { getCurrentAuthToken, getCurrentPatronId } from '../util/user'
import { getCurrentCoordinates } from '../util/geoLocation'
import { logoutInterceptor } from './util'

const device = parser()

const axiosConfig = {
  baseURL: `${appConfig.patronApiGateway}/core`,
  withCredentials: true,
  headers: {
    app: 'WWW',
    'ws-api': '2.1',
    'Content-Type': 'application/x-www-form-urlencoded',
    os: device.os.name,
    'os-version': device.os.version,
    'device-version': device.browser.name,
    'device-model': device.browser.version,
    'hoopla-version': process.env.REACT_APP_VERSION,
    'binge-pass-external-enabled': true,
    'binge-pass-internal-enabled': process.env.REACT_APP_BINGE_PASS_ENABLED,
  },
  transformRequest: [
    function (data, headers) {
      if (headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        var str = []
        for (let p in data)
          if (data[p]) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(data[p]))
          }
        return str.join('&')
      } else if (headers['Content-Type'] === 'application/json') {
        return JSON.stringify(data)
      }

      return data
    },
  ],
}

export const hooplaClient = axios.create(axiosConfig)

const authorizationInterceptor = (config) => {
  const token = getCurrentAuthToken()
  const kidsMode = isKidsMode()

  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }

  const coordinates = getCurrentCoordinates()
  if (!isEmpty(coordinates)) {
    // if the patron is browsing anonymously, try sending their coordinates if they allowed us to capture them
    // initially
    // we don't send these data if we know the patron already, as we can pull licensed titles from their library
    // country.
    config.headers['latitude'] = coordinates.latitude
    config.headers['longitude'] = coordinates.longitude
  }

  // set kids mode header
  if (kidsMode) config.headers['kids-mode'] = true

  // Add active patron id
  const patronId = getCurrentPatronId()
  if (patronId) {
    config.headers['patron-id'] = patronId
  }

  return config
}

hooplaClient.interceptors.request.use(authorizationInterceptor)
hooplaClient.interceptors.response.use(logoutInterceptor.onSuccess, (err) =>
  logoutInterceptor.onFailure(err, 'hooplaAPI'),
)
