import { getCurrentAuthToken } from 'util/user'
import axios from 'axios'
import appConfig from 'config'
import { isKidsMode } from 'state/useKidsMode'
import { logoutInterceptor } from './util'

export const holdsClient = axios.create({
  baseURL: `${appConfig.patronApiGateway}/holds`,
  headers: {
    'content-type': 'application/json',
    'binge-pass-external-enabled': process.env.REACT_APP_BINGE_PASS_ENABLED,
  },
})

const authorizationInterceptor = (config) => {
  const token = getCurrentAuthToken()

  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }

  config.headers['kids-mode'] = isKidsMode()

  // LJ3: this fixes an axios bug. POSTs should always have a json data object, even if it's an empty object
  if (config.method === 'post' && !config.data) {
    config.data = {}
  }

  return config
}

holdsClient.interceptors.request.use(authorizationInterceptor)
holdsClient.interceptors.response.use(logoutInterceptor.onSuccess, (err) =>
  logoutInterceptor.onFailure(err, 'holdsAPI'),
)
